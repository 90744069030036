import React from 'react';
import { Link } from 'react-router-dom';
import { Col } from 'react-bootstrap';
interface EmptyPageProps {
    imageSrc: string;
    altText: string;
    description: string;
    linkText: string;
    linkTo: string;
    t: any;
}

const EmptyPage: React.FC<EmptyPageProps> = ({ imageSrc, altText, description, linkText, linkTo, t }) => {
    return (
        <div className="d-flex flex-column justify-content-center align-items-center flex-grow-1 mt-xxl-5" style={{padding: "75px 0"}}>
            <img src={imageSrc} alt={t(altText)} className="image-fluid mb-4" />
            <Col xxl={4} className="mx-auto">
                <p className="fs-5 text-center mb-4">{t(description)}</p>
            </Col>
            <Col xs={12} sm={6} lg={3} className="mx-auto">
                <Link to={linkTo} className="d-flex align-items-center justify-content-center btn btn-primary w-100 text-capitalize">
                    <i className='bi bi-plus-lg me-2'></i>
                    {t(linkText)}
                </Link>
            </Col>
        </div>
    );
};

export default EmptyPage;
