import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useReactiveVar } from '@apollo/client';
import { useCreateMenuCategoryMutation } from '@me-team/host/src/modules/menu/graphql/menu.hooks';
import { currentCompanyIdVar } from '@me-team/host/src/apollo/globalVar/state';
import DishesBranch from "../../images/dishes-branch.svg";
import EmployeeBranch from "../../images/employee-branch.svg";
import TariffBranch from "../../images/tariff-branch.svg";

interface Category {
    id: number;
    name: string;
}

interface CategoryFormModalProps {
    show: boolean;
    handleClose: () => void;
    category: Category | null;
    parentCategory: Category | null;
    onSave: () => void;
}

const CategoryFormModal: React.FC<CategoryFormModalProps> = (
    {
        show,
        handleClose,
        category,
        parentCategory,
        onSave,
    }
) => {
    const {t} = useTranslation();

    // State variables
    const [language, setLanguage] = useState<string>('en');
    const [name, setName] = useState<string>('');
    const [nameLength, setNameLength] = useState<number>(0);
    const [description, setDescription] = useState<string>('');
    const [descriptionLength, setDescriptionLength] = useState<number>(0);
    const [icon, setIcon] = useState<string | null>(null);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const currentCompanyId = useReactiveVar(currentCompanyIdVar);

    // Mutation hook
    const [createMenuCategory, {loading: creating, error: createError}] = useCreateMenuCategoryMutation()

    useEffect(() => {
        if (category) {
            setName(category.name);
            setNameLength(category.name.length);
        } else {
            setName('');
            setNameLength(0);
            setDescription('');
            setDescriptionLength(0);
            setIcon(null);
        }
    }, [category]);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        if (!name.trim()) {
            setErrorMessage(t('Fill in this field.'));
            return;
        }

        // Prepare input data
        const input = {
            name: name.trim(),
            description: description.trim() || null,
            parent: parentCategory?.id || null,
            favicon: icon || null,
        };

        try {
            await createMenuCategory({
                variables: {
                    companyId: parseInt(currentCompanyId),
                    input,
                },
            });

            onSave();
            handleClose();
        } catch (error: any) {
            console.error('Error creating category:', error);
            if (error.graphQLErrors && error.graphQLErrors.length > 0) {
                const serverMessage = error.graphQLErrors[0].message;
                if (serverMessage.includes('already exists')) {
                    setErrorMessage(t('A category with this name already exists.'));
                } else {
                    setErrorMessage(t('An error occurred while creating the category.'));
                }
            } else {
                setErrorMessage(t('An error occurred while creating the category.'));
            }
        }
    };

    const title = category
        ? t('Edit Category')
        : parentCategory
            ? t('Create New Category in {{parentName}}', {parentName: parentCategory.name})
            : t('Create New Category');

    // Define available icons, this is mock icons, need take it from Figma
    const availableIcons = [
        {name: 'Star', value: 'bi-star', IconComponent: DishesBranch},
        {name: 'Heart', value: 'bi-heart', IconComponent: EmployeeBranch},
        {name: 'Big', value: 'bi-big', IconComponent: TariffBranch},
    ];

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Form onSubmit={handleSubmit}>
                <Modal.Body>
                    {/* Category Name */}
                    <Form.Group controlId="categoryName">
                        <Form.Label>
                            {t('Category Name')} <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                            type="text"
                            maxLength={50}
                            placeholder={t('Enter a category name')}
                            value={name}
                            onChange={(e) => {
                                setName(e.target.value);
                                setNameLength(e.target.value.length);
                                setErrorMessage('');
                            }}
                        />
                        <Form.Text className="text-muted">{`${nameLength}/50`}</Form.Text>
                        {errorMessage && <div className="text-danger">{errorMessage}</div>}
                    </Form.Group>
                    {/* Description */}
                    <Form.Group controlId="categoryDescription">
                        <Form.Label>{t('Description')}</Form.Label>
                        <Form.Control
                            as="textarea"
                            maxLength={400}
                            placeholder={t('Enter a category description (optional)')}
                            value={description}
                            onChange={(e) => {
                                setDescription(e.target.value);
                                setDescriptionLength(e.target.value.length);
                            }}
                        />
                        <Form.Text className="text-muted">{`${descriptionLength}/400`}</Form.Text>
                    </Form.Group>
                    {/* Category Icon */}
                    <Form.Group controlId="categoryIcon">
                        <Form.Label>{t('Category Icon')}</Form.Label>
                        <div className="d-flex flex-wrap">
                            {availableIcons.map((iconOption) => {
                                const Icon = iconOption.IconComponent;
                                return (
                                    <Button
                                        key={iconOption.value}
                                        variant={icon === iconOption.value ? 'primary' : 'outline-secondary'}
                                        className="me-2 mb-2 p-2"
                                        onClick={() => {
                                            if (icon === iconOption.value) {
                                                setIcon(null); // Deselect if clicked again
                                            } else {
                                                setIcon(iconOption.value);
                                            }
                                        }}
                                    >
                                        {/* Render the SVG icon with specified size */}
                                        <Icon width={48} height={48}/>
                                    </Button>
                                );
                            })}
                        </div>
                    </Form.Group>
                    {creating && <div>{t('Creating category...')}</div>}
                    {createError && <div className="text-danger">{t('Error creating category.')}</div>}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose} disabled={creating}>
                        {t('Cancel')}
                    </Button>
                    <Button variant="primary" type="submit" disabled={creating}>
                        {category ? t('Save') : t('Create')}
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};

export default CategoryFormModal;
