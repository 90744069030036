import React, { useEffect, useState } from 'react';
import {useTranslation} from 'react-i18next';
import { TableHeader, Pagination, ReactSelect, FiltersButton, ProgressBar, EmptyPage } from '@services/ui-components';
import {Row, Col, Button, OverlayTrigger, Tooltip} from 'react-bootstrap'
import { useNavigate } from 'react-router-dom';
import {
    GetMenuItemsQueryVariables, useCloneMenuItemMutation, useDeleteMenuItemMutation, useGetCurrentMenuItemLazyQuery,
    useGetMenuCategoriesQuery,
    useGetMenuItemsQuery
} from '@me-team/host/src/modules/menu/graphql/menu.hooks';
import ModalCloneMenuItem from './ModalCloneMenuItem';
import ModalDeleteMenuItem from './ModalDeleteMenuItem';
import { getSteps, IStep } from './constants';
import emptyImage from "../../images/dishes.png";

const MenuItems: React.FC = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const steps: IStep[] = getSteps(t);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(20);
    const [showFilters, setShowFilters] = useState(false);
    const toggleFilters = () => setShowFilters(!showFilters);
    const [filter, setFilter] = useState({
        search: '',
        menuCategoryId: null,
    });

    const [currentFilter, setCurrentFilter] = useState({
        search: '',
        menuCategoryId: null,
    });
    const [sortBy, setSortBy] = useState('name');
    const [order, setOrder] = useState('ASC');

    const { data: menuItemsData, loading, error, refetch: refetchMenuItems } = useGetMenuItemsQuery({
        variables: {
            itemsPerPage,
            page: currentPage,
            sortBy,
            order,
            search: filter.search,
            menuCategoryId: filter.menuCategoryId,
        },
    });

    // State variables for cloning
    const [showCloneModal, setShowCloneModal] = useState(false);
    const [dishToClone, setDishToClone] = useState<any>(null);
    const [clonedDishName, setClonedDishName] = useState('');
    const [selectedCategory, setSelectedCategory] = useState<any>(null);

    const { data: categoriesData } = useGetMenuCategoriesQuery();
    const [getCurrentMenuItem, { data: currentMenuItemData }] = useGetCurrentMenuItemLazyQuery();
    const [cloneMenuItemMutation] = useCloneMenuItemMutation();


    // State variables for deleting
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [itemToDelete, setItemToDelete] = useState<any>(null);
    const [deleteModalMessage, setDeleteModalMessage] = useState('');
    const [deleteMenuItemMutation] = useDeleteMenuItemMutation();


    const handleCloneClick = (menuItem: any) => {
        getCurrentMenuItem({ variables: { id: parseInt(menuItem.id) } });
        setShowCloneModal(true);
    };

    const handleCloneDish = async () => {
        if (!dishToClone || !clonedDishName || !selectedCategory) return;

        const input = {
            name: clonedDishName,
            categories: [parseInt(selectedCategory.value)],
            types: dishToClone.types.map((type: any) => parseInt(type.id)),
            unit: parseInt(dishToClone.unit.id),
            portions: dishToClone.portions.map((portion: any) => ({
                name: portion.name,
                price: portion.price,
                volume: portion.volume,
            })),
            description: dishToClone.description,
            composition: dishToClone.composition,
            cookingTime: dishToClone.cookingTime,
            isLongCookingTime: dishToClone.isLongCookingTime,
            markers: dishToClone.markers.map((marker: any) => parseInt(marker.id)),
            allergens: dishToClone.allergens.map((allergen: any) => parseInt(allergen.id)),
            // Include supplements if necessary
            // supplements: dishToClone.supplements.map((supplement: any) => parseInt(supplement.id)),
            // Disable availability for all menu types if applicable
            // availability: false,
        };

        try {
            const result = await cloneMenuItemMutation({
                variables: {
                    cloneMenuItemId: parseInt(dishToClone.id),
                    input: input,
                },
            });
            const newDishId = result.data.cloneMenuItem.id;
            navigate(`/branch/menu/item/${newDishId}`);
        } catch (error) {
            console.error("Error cloning menu item:", error);
        }
    };


    useEffect(() => {
        if (currentMenuItemData && currentMenuItemData.user.company.menuItems.items.length > 0) {
            const menuItem = currentMenuItemData.user.company.menuItems.items[0];
            setDishToClone(menuItem);
            setClonedDishName(`Копія - ${menuItem.name}`);
            // Pre-select the category
            if (menuItem.categories.length > 0) {
                setSelectedCategory({
                    value: menuItem.categories[0].id,
                    label: menuItem.categories[0].name,
                });
            }
        }
    }, [currentMenuItemData]);

    const handleDeleteClick = (menuItem: any) => {
        setItemToDelete(menuItem);
        // WAIT FOR BACKEND VALUE MENU
        const isUsedInMenu = menuItem.isUsedInMenu;

        if (isUsedInMenu) {
            setDeleteModalMessage(
                t("Are you sure you want to delete this dish? The dish has links in the menu. It will be moved to the archive, and you will be able to restore it.")
            );
        } else {
            setDeleteModalMessage(
                t("Are you sure you want to delete this dish? It will be moved to the archive, and you will be able to restore it.")
            );
        }

        setShowDeleteModal(true);
    };

    const handleConfirmDelete = async () => {
        if (!itemToDelete) return;

        try {
            await deleteMenuItemMutation({
                variables: { id: parseInt(itemToDelete) },
            });

            refetchMenuItems();

            setShowDeleteModal(false);
            setItemToDelete(null);
        } catch (error) {
            console.error('Error deleting menu item:', error);
        }
    };

    const handleApplyFilter = () => {
        setFilter(currentFilter);
        setCurrentPage(1);
    };

    const handleClearFilter = () => {
        setFilter({ search: '', menuCategoryId: null });
        setCurrentFilter({ search: '', menuCategoryId: null });
        setSortBy('name');
        setOrder('ASC');
        setCurrentPage(1);
    };

    const handlePageChange = (pageNumber: number) => {
        setCurrentPage(pageNumber);
    };


    const options = [
        { value: 'chocolate', label: 'Chocolate' },
        { value: 'strawberry', label: 'Strawberry' },
        { value: 'vanilla', label: 'Vanilla' }
    ];

    const handleEditMenuItem = (id: string) => {
        navigate(`/branch/menu/item/${id}`)
    };

    const menuItems  = menuItemsData?.user.company.menuItems.items.map((item: any) => ({
        id: item.id,
        name: item.name,
        categories: item.categories.map((category: any) => {
            const subcategories = category.subcategories.map((sub: any) => sub.name).join(', ');
            return `${category.name}${subcategories ? '/' + subcategories : ''}`;
        }).join(', '),
        price: item.portions.map((portion: any) => `${portion.price} грн`).join('/'),
        volume: item.portions.map((volume: any) => `${volume.volume} г`).join('/'),
    })) || [];

    const pageCount = parseInt(menuItemsData?.user.company.menuItems.pageCount || '0');
    const totalItems = menuItemsData?.user.company.menuItems.totalCount || 0;
    const firstItemIndex = (currentPage - 1) * itemsPerPage + 1;
    const lastItemIndex = Math.min(currentPage * itemsPerPage, totalItems);

    const categoryOptions = categoriesData?.user.company.menuCategories.categories.flatMap((category) => {
        const options = [{ value: category.id, label: category.name }];
        const subcategoryOptions = category.subcategories.map((sub) => ({
            value: sub.id,
            label: `${category.name} / ${sub.name}`,
        }));
        return [...options, ...subcategoryOptions];
    });

    const sortingOptions = [
        { value: { sortBy: 'name', order: 'ASC' }, label: t('Alphabetically (A-Z)') },
        { value: { sortBy: 'name', order: 'DESC' }, label: t('Alphabetically (Z-A)') },
        { value: { sortBy: 'price', order: 'ASC' }, label: t('From cheapest') },
        { value: { sortBy: 'price', order: 'DESC' }, label: t('From most expensive') },
    ];

    if (loading) return <p>{t('loading')}</p>;
    if (error) return <p>Error: {error.message}</p>;

    return (
        <div className="mb-4">
            <TableHeader
                title={t("List of dishes")}
                paths={[{ path: "/branches", label: t("Branches") }, { path: "/branch/menu/items", label: t("List of dishes") }]}
                buttons={
                    <Row className='gx-3 justify-content-end'>
                        <Col sm={6} xl={3} xxl={3} className="mb-2 mb-xxl-0">
                            <div className="position-relative">
                                <span className="position-absolute start-0 top-0 z-1 ps-2 pt-1 fs-8 text-grey">{t("Language version")}</span>
                                <ReactSelect
                                    id="listOfDishes-language-field"
                                    value={{value: 'Українська (основна)', label: 'Українська (основна)'}}
                                    options={[]}
                                    placeholder={t("Type of branch")}
                                    onChange={() => {}}
                                    isSearchable={true}
                                    disabled={false}
                                    isMeMenu={true}
                                    isTitle={true}
                                />
                            </div>
                        </Col>
                        <Col sm={6} xl={4} xxl={3} className="mb-2 mb-xxl-0">
                            <Button className="btn btn-linear-gradient border-0 fw-bold py-6 text-capitalize w-100"><i className='bi bi-book me-2'></i>{t('Create menu')}</Button>
                        </Col>
                        <Col xs={10} xl={3}>
                            <Button onClick={() => navigate(`/branch/menu/create-item`)} className="btn btn-primary fw-bold text-capitalize w-100"><i className='bi bi-plus-lg me-lg-2'></i> {t('Add dish')}</Button>
                        </Col>
                        <Col xs={2} xxl={2}>
                            <FiltersButton filter={filter} showFilters={showFilters} onClick={toggleFilters} classesText="d-none d-xxl-block"/>
                        </Col>
                    </Row>
                }
                showFilters={showFilters}
                colSize={4}
                btnColSize={8}
            >
                <Row className='gx-3'>
                    <Col lg={9}>
                        <Row className='gx-3'>
                            <Col lg={4}>
                                <div className="position-relative mb-3 mb-lg-0">
                                    <input
                                        id="listOfDishes-search-field"
                                        type="text"
                                        className="form-control rounded-1 ps-6 pe-5 text-truncate"
                                        placeholder={t("Search by name, description")}
                                        value={currentFilter.search}
                                        onChange={(e) => setCurrentFilter({ ...currentFilter, search: e.target.value })}
                                    />
                                    <i className='bi bi-search position-absolute top-50 end-0 translate-middle-y text-dark me-3'></i>
                                </div>
                            </Col>
                            <Col lg={4}>
                                <ReactSelect
                                    id="listOfDishes-category-field"
                                    value={categoryOptions?.find(option => option.value === currentFilter.menuCategoryId)}
                                    options={categoryOptions}
                                    placeholder={t("Category")}
                                    onChange={(selectedOption) =>
                                        setCurrentFilter({ ...currentFilter, menuCategoryId: selectedOption ? selectedOption.value : null })
                                    }
                                    isSearchable={true}
                                />
                            </Col>
                            <Col lg={4}>
                                <div className="position-relative">
                                    <span className="position-absolute start-0 top-0 z-1 ps-2 pt-1 fs-8 text-grey">Sort</span>
                                    <ReactSelect
                                        id="listOfDishes-sort-field"
                                        value={sortingOptions.find(option => option.value.sortBy === sortBy && option.value.order === order)}
                                        options={sortingOptions}
                                        placeholder={t("Sort by")}
                                        onChange={(selectedOption) => {
                                            setSortBy(selectedOption.value.sortBy);
                                            setOrder(selectedOption.value.order);
                                        }}
                                        isSearchable={false}
                                        isTitle={true}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col lg={3}>
                        <Row className='gx-3 h-100'>
                            <Col xs={6}>
                                <Button variant="outline-dark" className="border-grey w-100 h-100 fw-normal"
                                        onClick={handleClearFilter}
                                >{t("clear")}</Button>
                            </Col>
                            <Col xs={6}>
                                <Button variant="primary" className="btn btn-primary w-100 h-100 fw-normal px-1"
                                        onClick={handleApplyFilter}
                                >{t("apply")}</Button>
                            </Col>

                        </Row>
                    </Col>
                </Row>
            </TableHeader>

            { menuItems.length > 0 ? (
                <>
                    <ProgressBar currentStep={2} steps={steps}/>
                    <div className="table-responsive mb-4">
                        <table className="table mb-2">
                            <thead>
                            <tr className='fs-7'>
                                <th className="col-1 text-center px-4">{t("photo")}</th>
                                <th>{t("name")}</th>
                                <th>{t("categoriesSubcategories")}</th>
                                <th>{t("pricePrices")}</th>
                                <th>{t("volume")}</th>
                                <th>{t("menu")}</th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                menuItems.map((menuItem: any) => {
                                    const categoryList = menuItem.categories.split(", ");
                                    const displayedCategories = categoryList.length > 2
                                        ? categoryList.slice(0, 2).join("; ")
                                        : menuItem.categories.split(", ").join("; ");
                                    const hiddenCategories = categoryList.length > 2 ? categoryList.slice(2).join("; ") : "";
                                    return (<tr key={menuItem.id} className="align-middle">
                                        <td className="align-middle">
                                            <div className="d-flex justify-content-center align-items-center rounded-circle border border-secondary mx-auto img-size">
                                                {/* TODO: which image default if images is not */}
                                                <img src={menuItem?.image ? menuItem?.image : ''} alt={menuItem?.name} className="rounded-circle mw-100 mh-100" />
                                            </div>
                                        </td>
                                        <td>{menuItem.name}</td>
                                        <td>
                                            {
                                                hiddenCategories
                                                    ? <>
                                                        {displayedCategories};
                                                        <OverlayTrigger
                                                            placement="bottom"
                                                            overlay={<Tooltip>{hiddenCategories}</Tooltip>}>
                                                            <span className="cursor-pointer">{' '}...</span>
                                                        </OverlayTrigger>
                                                    </>
                                                    : displayedCategories
                                            }
                                        </td>
                                        <td className="text-nowrap">{menuItem.price}</td>
                                        <td className="text-nowrap">{menuItem.volume}</td>
                                        <td>{menuItem.menu}</td>
                                        <td>
                                            <div className="d-flex gap-2 justify-content-end align-items-center flex-nowrap">
                                                <OverlayTrigger overlay={<Tooltip>{t('Clone')}</Tooltip>}>
                                                    <Button
                                                        variant="outline-secondary"
                                                        onClick={() => handleCloneClick(menuItem)}
                                                        className="py-2 text-dark rounded-1"
                                                    >
                                                        <i className="bi bi-stickies"></i>
                                                    </Button>
                                                </OverlayTrigger>
                                                <OverlayTrigger overlay={<Tooltip>{t('Edit')}</Tooltip>}>
                                                    <Button
                                                        variant="outline-secondary"
                                                        onClick={() => handleEditMenuItem(menuItem.id)}
                                                        className="p-2 fs-7 text-dark rounded-1"
                                                    >
                                                        <i className="bi bi-pencil px-1"></i>
                                                    </Button>
                                                </OverlayTrigger>
                                                <OverlayTrigger overlay={<Tooltip>{t('Delete')}</Tooltip>}>
                                                    <Button
                                                        variant="outline-secondary"
                                                        className="py-2 text-dark rounded-1"
                                                        onClick={() => handleDeleteClick(menuItem.id)}
                                                    >
                                                        <i className="bi bi-trash3"></i>
                                                    </Button>
                                                </OverlayTrigger>
                                            </div>
                                        </td>
                                    </tr>
                                )})
                            }
                            </tbody>
                        </table>
                    </div>
                </>) : (
                    <EmptyPage
                        imageSrc={emptyImage}
                        altText="Dishes"
                        description="You don't have any dishes yet. Create your first dish"
                        linkText="Create dish"
                        linkTo="/branch/menu/create-item" t={t}
                    />
                )
            }

            {menuItems.length > 0 ? (
                <div className="d-flex justify-content-between align-items-center">
                    <Pagination
                        t={t}
                        pages={Number(pageCount)}
                        currentPage={currentPage}
                        onPageChange={handlePageChange}
                        firstItemIndex={firstItemIndex}
                        lastItemIndex={lastItemIndex}
                        totalItems={totalItems}
                    />
                </div>
            ) : null}

            <ModalCloneMenuItem
                showCloneModal={showCloneModal}
                setShowCloneModal={setShowCloneModal}
                dishToClone={dishToClone}
                clonedDishName={clonedDishName}
                setClonedDishName={setClonedDishName}
                selectedCategory={selectedCategory}
                setSelectedCategory={setSelectedCategory}
                categoriesData={categoriesData}
                handleCloneDish={handleCloneDish}
            />
            <ModalDeleteMenuItem
                showDeleteModal={showDeleteModal}
                setShowDeleteModal={setShowDeleteModal}
                deleteModalMessage={deleteModalMessage}
                handleConfirmDelete={handleConfirmDelete}
            />
        </div>
    );
};

export default MenuItems;
