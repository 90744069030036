import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

interface Category {
    id: number;
    name: string;
    itemsAttached: boolean;
    subcategories: Category[];
    subcategoriesHaveItems: boolean;
}

interface DeleteCategoryModalProps {
    show: boolean;
    handleClose: () => void;
    category: Category;
    onDelete: () => void;
}

const DeleteCategoryModal: React.FC<DeleteCategoryModalProps> = ({
                                                                     show,
                                                                     handleClose,
                                                                     category,
                                                                     onDelete,
                                                                 }) => {
    const { t } = useTranslation();

    // Determine which variant to display
    const getVariant = () => {
        if (category.subcategories.length === 0) {
            // Category has no subcategories
            if (!category.itemsAttached) {
                // Variant 1: No items attached
                return 'variant1';
            } else {
                // Variant 2: Items attached
                return 'variant2';
            }
        } else {
            // Category has subcategories
            if (!category.itemsAttached) {
                if (category.subcategoriesHaveItems) {
                    // Variant 4: Subcategories have items attached
                    return 'variant4';
                } else {
                    // Variant 3: Subcategories have no items attached
                    return 'variant3';
                }
            } else {
                // Variant 2: Category has items attached
                return 'variant2';
            }
        }
    };

    const variant = getVariant();

    // Modal content based on variant
    const renderModalContent = () => {
        switch (variant) {
            case 'variant1':
                return (
                    <>
                        <Modal.Body>
                            {t('Do you really want to delete this category?')}
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>
                                {t('No')}
                            </Button>
                            <Button variant="primary" onClick={onDelete}>
                                {t('Yes')}
                            </Button>
                        </Modal.Footer>
                    </>
                );
            case 'variant2':
                return (
                    <>
                        <Modal.Body>
                            {t('Deleting this category is not possible because it is linked to items.')}
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="primary" onClick={handleClose}>
                                {t('Ok')}
                            </Button>
                        </Modal.Footer>
                    </>
                );
            case 'variant3':
                return (
                    <>
                        <Modal.Body>
                            {t(
                                'This category has subcategories that will also be deleted. Move them if you want to keep them.'
                            )}
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>
                                {t('Cancel')}
                            </Button>
                            <Button variant="primary" onClick={onDelete}>
                                {t('Delete Category')}
                            </Button>
                        </Modal.Footer>
                    </>
                );
            case 'variant4':
                return (
                    <>
                        <Modal.Body>
                            {t(
                                'This category has subcategories that are linked to items. Move the subcategories linked to items to delete the category.'
                            )}
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="primary" onClick={handleClose}>
                                {t('Ok')}
                            </Button>
                        </Modal.Footer>
                    </>
                );
            default:
                return null;
        }
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>{t('Delete Category')}</Modal.Title>
            </Modal.Header>
            {renderModalContent()}
        </Modal>
    );
};

export default DeleteCategoryModal;
