/* eslint-disable */
import * as Types from '../../../../graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const GetMenuItemsDocument = gql`
    query GetMenuItems($itemsPerPage: Int!, $page: Int!, $sortBy: String, $order: String, $id: Int, $search: String, $menuCategoryId: Int, $isDeleted: Boolean) {
  user {
    company {
      menuItems(
        page: $page
        itemsPerPage: $itemsPerPage
        sortBy: $sortBy
        order: $order
        id: $id
        search: $search
        menuCategoryId: $menuCategoryId
        isDeleted: $isDeleted
      ) {
        items {
          id
          name
          description
          categories {
            id
            name
            subcategories {
              id
              name
            }
          }
          portions {
            price
            volume
            name
          }
        }
        totalCount
        currentPage
        itemsPerPage
        pageCount
      }
    }
  }
}
    `;

/**
 * __useGetMenuItemsQuery__
 *
 * To run a query within a React component, call `useGetMenuItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMenuItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMenuItemsQuery({
 *   variables: {
 *      itemsPerPage: // value for 'itemsPerPage'
 *      page: // value for 'page'
 *      sortBy: // value for 'sortBy'
 *      order: // value for 'order'
 *      id: // value for 'id'
 *      search: // value for 'search'
 *      menuCategoryId: // value for 'menuCategoryId'
 *      isDeleted: // value for 'isDeleted'
 *   },
 * });
 */
export function useGetMenuItemsQuery(baseOptions: Apollo.QueryHookOptions<GetMenuItemsQuery, GetMenuItemsQueryVariables> & ({ variables: GetMenuItemsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMenuItemsQuery, GetMenuItemsQueryVariables>(GetMenuItemsDocument, options);
      }
export function useGetMenuItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMenuItemsQuery, GetMenuItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMenuItemsQuery, GetMenuItemsQueryVariables>(GetMenuItemsDocument, options);
        }
export function useGetMenuItemsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetMenuItemsQuery, GetMenuItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetMenuItemsQuery, GetMenuItemsQueryVariables>(GetMenuItemsDocument, options);
        }
export type GetMenuItemsQueryHookResult = ReturnType<typeof useGetMenuItemsQuery>;
export type GetMenuItemsLazyQueryHookResult = ReturnType<typeof useGetMenuItemsLazyQuery>;
export type GetMenuItemsSuspenseQueryHookResult = ReturnType<typeof useGetMenuItemsSuspenseQuery>;
export type GetMenuItemsQueryResult = Apollo.QueryResult<GetMenuItemsQuery, GetMenuItemsQueryVariables>;
export const GetMenuAllergensDocument = gql`
    query GetMenuAllergens {
  menuAllergens {
    id
    name
    slug
  }
}
    `;

/**
 * __useGetMenuAllergensQuery__
 *
 * To run a query within a React component, call `useGetMenuAllergensQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMenuAllergensQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMenuAllergensQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMenuAllergensQuery(baseOptions?: Apollo.QueryHookOptions<GetMenuAllergensQuery, GetMenuAllergensQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMenuAllergensQuery, GetMenuAllergensQueryVariables>(GetMenuAllergensDocument, options);
      }
export function useGetMenuAllergensLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMenuAllergensQuery, GetMenuAllergensQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMenuAllergensQuery, GetMenuAllergensQueryVariables>(GetMenuAllergensDocument, options);
        }
export function useGetMenuAllergensSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetMenuAllergensQuery, GetMenuAllergensQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetMenuAllergensQuery, GetMenuAllergensQueryVariables>(GetMenuAllergensDocument, options);
        }
export type GetMenuAllergensQueryHookResult = ReturnType<typeof useGetMenuAllergensQuery>;
export type GetMenuAllergensLazyQueryHookResult = ReturnType<typeof useGetMenuAllergensLazyQuery>;
export type GetMenuAllergensSuspenseQueryHookResult = ReturnType<typeof useGetMenuAllergensSuspenseQuery>;
export type GetMenuAllergensQueryResult = Apollo.QueryResult<GetMenuAllergensQuery, GetMenuAllergensQueryVariables>;
export const GetMenuTypesDocument = gql`
    query GetMenuTypes {
  menuTypes {
    id
    name
    slug
  }
}
    `;

/**
 * __useGetMenuTypesQuery__
 *
 * To run a query within a React component, call `useGetMenuTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMenuTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMenuTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMenuTypesQuery(baseOptions?: Apollo.QueryHookOptions<GetMenuTypesQuery, GetMenuTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMenuTypesQuery, GetMenuTypesQueryVariables>(GetMenuTypesDocument, options);
      }
export function useGetMenuTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMenuTypesQuery, GetMenuTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMenuTypesQuery, GetMenuTypesQueryVariables>(GetMenuTypesDocument, options);
        }
export function useGetMenuTypesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetMenuTypesQuery, GetMenuTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetMenuTypesQuery, GetMenuTypesQueryVariables>(GetMenuTypesDocument, options);
        }
export type GetMenuTypesQueryHookResult = ReturnType<typeof useGetMenuTypesQuery>;
export type GetMenuTypesLazyQueryHookResult = ReturnType<typeof useGetMenuTypesLazyQuery>;
export type GetMenuTypesSuspenseQueryHookResult = ReturnType<typeof useGetMenuTypesSuspenseQuery>;
export type GetMenuTypesQueryResult = Apollo.QueryResult<GetMenuTypesQuery, GetMenuTypesQueryVariables>;
export const GetMenuMarkersDocument = gql`
    query GetMenuMarkers {
  menuMarkers {
    id
    name
    slug
  }
}
    `;

/**
 * __useGetMenuMarkersQuery__
 *
 * To run a query within a React component, call `useGetMenuMarkersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMenuMarkersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMenuMarkersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMenuMarkersQuery(baseOptions?: Apollo.QueryHookOptions<GetMenuMarkersQuery, GetMenuMarkersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMenuMarkersQuery, GetMenuMarkersQueryVariables>(GetMenuMarkersDocument, options);
      }
export function useGetMenuMarkersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMenuMarkersQuery, GetMenuMarkersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMenuMarkersQuery, GetMenuMarkersQueryVariables>(GetMenuMarkersDocument, options);
        }
export function useGetMenuMarkersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetMenuMarkersQuery, GetMenuMarkersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetMenuMarkersQuery, GetMenuMarkersQueryVariables>(GetMenuMarkersDocument, options);
        }
export type GetMenuMarkersQueryHookResult = ReturnType<typeof useGetMenuMarkersQuery>;
export type GetMenuMarkersLazyQueryHookResult = ReturnType<typeof useGetMenuMarkersLazyQuery>;
export type GetMenuMarkersSuspenseQueryHookResult = ReturnType<typeof useGetMenuMarkersSuspenseQuery>;
export type GetMenuMarkersQueryResult = Apollo.QueryResult<GetMenuMarkersQuery, GetMenuMarkersQueryVariables>;
export const GetMenuStatusesDocument = gql`
    query GetMenuStatuses {
  menuStatuses {
    id
    name
    slug
  }
}
    `;

/**
 * __useGetMenuStatusesQuery__
 *
 * To run a query within a React component, call `useGetMenuStatusesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMenuStatusesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMenuStatusesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMenuStatusesQuery(baseOptions?: Apollo.QueryHookOptions<GetMenuStatusesQuery, GetMenuStatusesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMenuStatusesQuery, GetMenuStatusesQueryVariables>(GetMenuStatusesDocument, options);
      }
export function useGetMenuStatusesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMenuStatusesQuery, GetMenuStatusesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMenuStatusesQuery, GetMenuStatusesQueryVariables>(GetMenuStatusesDocument, options);
        }
export function useGetMenuStatusesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetMenuStatusesQuery, GetMenuStatusesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetMenuStatusesQuery, GetMenuStatusesQueryVariables>(GetMenuStatusesDocument, options);
        }
export type GetMenuStatusesQueryHookResult = ReturnType<typeof useGetMenuStatusesQuery>;
export type GetMenuStatusesLazyQueryHookResult = ReturnType<typeof useGetMenuStatusesLazyQuery>;
export type GetMenuStatusesSuspenseQueryHookResult = ReturnType<typeof useGetMenuStatusesSuspenseQuery>;
export type GetMenuStatusesQueryResult = Apollo.QueryResult<GetMenuStatusesQuery, GetMenuStatusesQueryVariables>;
export const GetMenuUnitsDocument = gql`
    query GetMenuUnits {
  relations {
    units {
      id
      name
    }
  }
}
    `;

/**
 * __useGetMenuUnitsQuery__
 *
 * To run a query within a React component, call `useGetMenuUnitsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMenuUnitsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMenuUnitsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMenuUnitsQuery(baseOptions?: Apollo.QueryHookOptions<GetMenuUnitsQuery, GetMenuUnitsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMenuUnitsQuery, GetMenuUnitsQueryVariables>(GetMenuUnitsDocument, options);
      }
export function useGetMenuUnitsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMenuUnitsQuery, GetMenuUnitsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMenuUnitsQuery, GetMenuUnitsQueryVariables>(GetMenuUnitsDocument, options);
        }
export function useGetMenuUnitsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetMenuUnitsQuery, GetMenuUnitsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetMenuUnitsQuery, GetMenuUnitsQueryVariables>(GetMenuUnitsDocument, options);
        }
export type GetMenuUnitsQueryHookResult = ReturnType<typeof useGetMenuUnitsQuery>;
export type GetMenuUnitsLazyQueryHookResult = ReturnType<typeof useGetMenuUnitsLazyQuery>;
export type GetMenuUnitsSuspenseQueryHookResult = ReturnType<typeof useGetMenuUnitsSuspenseQuery>;
export type GetMenuUnitsQueryResult = Apollo.QueryResult<GetMenuUnitsQuery, GetMenuUnitsQueryVariables>;
export const CreateMenuItemDocument = gql`
    mutation CreateMenuItem($companyId: Int!, $name: String!, $types: [Int!]!, $categories: [Int!]!, $description: String, $composition: String, $unit: Int!, $portions: [MenuItemPortionInput!]!, $cookingTime: String, $isLongCookingTime: Boolean, $markers: [Int!], $allergens: [Int!]) {
  createMenuItem(
    companyId: $companyId
    input: {name: $name, types: $types, categories: $categories, unit: $unit, portions: $portions, description: $description, composition: $composition, cookingTime: $cookingTime, isLongCookingTime: $isLongCookingTime, markers: $markers, allergens: $allergens}
  ) {
    name
    allergens {
      id
      label
      name
    }
    categories {
      id
      name
    }
    id
    types {
      id
      name
    }
    unit {
      id
      name
    }
    portions {
      id
      price
      name
      volume
    }
    supplements {
      id
      name
    }
    description
    composition
  }
}
    `;
export type CreateMenuItemMutationFn = Apollo.MutationFunction<CreateMenuItemMutation, CreateMenuItemMutationVariables>;

/**
 * __useCreateMenuItemMutation__
 *
 * To run a mutation, you first call `useCreateMenuItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMenuItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMenuItemMutation, { data, loading, error }] = useCreateMenuItemMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      name: // value for 'name'
 *      types: // value for 'types'
 *      categories: // value for 'categories'
 *      description: // value for 'description'
 *      composition: // value for 'composition'
 *      unit: // value for 'unit'
 *      portions: // value for 'portions'
 *      cookingTime: // value for 'cookingTime'
 *      isLongCookingTime: // value for 'isLongCookingTime'
 *      markers: // value for 'markers'
 *      allergens: // value for 'allergens'
 *   },
 * });
 */
export function useCreateMenuItemMutation(baseOptions?: Apollo.MutationHookOptions<CreateMenuItemMutation, CreateMenuItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateMenuItemMutation, CreateMenuItemMutationVariables>(CreateMenuItemDocument, options);
      }
export type CreateMenuItemMutationHookResult = ReturnType<typeof useCreateMenuItemMutation>;
export type CreateMenuItemMutationResult = Apollo.MutationResult<CreateMenuItemMutation>;
export type CreateMenuItemMutationOptions = Apollo.BaseMutationOptions<CreateMenuItemMutation, CreateMenuItemMutationVariables>;
export const GetMenuSupplementsDocument = gql`
    query GetMenuSupplements($search: String) {
  user {
    company {
      menuSupplements(search: $search) {
        supplements {
          options {
            id
            name
          }
          name
          groupName
          id
        }
      }
    }
  }
}
    `;

/**
 * __useGetMenuSupplementsQuery__
 *
 * To run a query within a React component, call `useGetMenuSupplementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMenuSupplementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMenuSupplementsQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useGetMenuSupplementsQuery(baseOptions?: Apollo.QueryHookOptions<GetMenuSupplementsQuery, GetMenuSupplementsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMenuSupplementsQuery, GetMenuSupplementsQueryVariables>(GetMenuSupplementsDocument, options);
      }
export function useGetMenuSupplementsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMenuSupplementsQuery, GetMenuSupplementsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMenuSupplementsQuery, GetMenuSupplementsQueryVariables>(GetMenuSupplementsDocument, options);
        }
export function useGetMenuSupplementsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetMenuSupplementsQuery, GetMenuSupplementsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetMenuSupplementsQuery, GetMenuSupplementsQueryVariables>(GetMenuSupplementsDocument, options);
        }
export type GetMenuSupplementsQueryHookResult = ReturnType<typeof useGetMenuSupplementsQuery>;
export type GetMenuSupplementsLazyQueryHookResult = ReturnType<typeof useGetMenuSupplementsLazyQuery>;
export type GetMenuSupplementsSuspenseQueryHookResult = ReturnType<typeof useGetMenuSupplementsSuspenseQuery>;
export type GetMenuSupplementsQueryResult = Apollo.QueryResult<GetMenuSupplementsQuery, GetMenuSupplementsQueryVariables>;
export const GetCurrentMenuItemDocument = gql`
    query GetCurrentMenuItem($id: Int) {
  user {
    company {
      menuItems(id: $id) {
        items {
          id
          name
          types {
            id
            name
          }
          categories {
            id
            name
            description
            parent {
              name
            }
            subcategories(id: 1) {
              name
            }
          }
          unit {
            id
            name
          }
          portions {
            id
            name
            volume
            price
          }
          description
          composition
          cookingTime
          isLongCookingTime
          markers {
            id
            name
          }
          allergens {
            id
            name
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetCurrentMenuItemQuery__
 *
 * To run a query within a React component, call `useGetCurrentMenuItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentMenuItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentMenuItemQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCurrentMenuItemQuery(baseOptions?: Apollo.QueryHookOptions<GetCurrentMenuItemQuery, GetCurrentMenuItemQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCurrentMenuItemQuery, GetCurrentMenuItemQueryVariables>(GetCurrentMenuItemDocument, options);
      }
export function useGetCurrentMenuItemLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentMenuItemQuery, GetCurrentMenuItemQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCurrentMenuItemQuery, GetCurrentMenuItemQueryVariables>(GetCurrentMenuItemDocument, options);
        }
export function useGetCurrentMenuItemSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetCurrentMenuItemQuery, GetCurrentMenuItemQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCurrentMenuItemQuery, GetCurrentMenuItemQueryVariables>(GetCurrentMenuItemDocument, options);
        }
export type GetCurrentMenuItemQueryHookResult = ReturnType<typeof useGetCurrentMenuItemQuery>;
export type GetCurrentMenuItemLazyQueryHookResult = ReturnType<typeof useGetCurrentMenuItemLazyQuery>;
export type GetCurrentMenuItemSuspenseQueryHookResult = ReturnType<typeof useGetCurrentMenuItemSuspenseQuery>;
export type GetCurrentMenuItemQueryResult = Apollo.QueryResult<GetCurrentMenuItemQuery, GetCurrentMenuItemQueryVariables>;
export const GetMenuCategoriesDocument = gql`
    query GetMenuCategories($page: Int, $itemsPerPage: Int, $id: Int, $search: String, $isDeleted: Boolean) {
  user {
    company {
      menuCategories(
        id: $id
        isDeleted: $isDeleted
        search: $search
        page: $page
        itemsPerPage: $itemsPerPage
      ) {
        categories {
          id
          name
          description
          favicon
          numberOfItems
          parent {
            id
            name
            description
          }
          subcategories {
            id
            name
            description
            numberOfItems
          }
          deleted
        }
        totalCount
        currentPage
        itemsPerPage
        pageCount
      }
    }
  }
}
    `;

/**
 * __useGetMenuCategoriesQuery__
 *
 * To run a query within a React component, call `useGetMenuCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMenuCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMenuCategoriesQuery({
 *   variables: {
 *      page: // value for 'page'
 *      itemsPerPage: // value for 'itemsPerPage'
 *      id: // value for 'id'
 *      search: // value for 'search'
 *      isDeleted: // value for 'isDeleted'
 *   },
 * });
 */
export function useGetMenuCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<GetMenuCategoriesQuery, GetMenuCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMenuCategoriesQuery, GetMenuCategoriesQueryVariables>(GetMenuCategoriesDocument, options);
      }
export function useGetMenuCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMenuCategoriesQuery, GetMenuCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMenuCategoriesQuery, GetMenuCategoriesQueryVariables>(GetMenuCategoriesDocument, options);
        }
export function useGetMenuCategoriesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetMenuCategoriesQuery, GetMenuCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetMenuCategoriesQuery, GetMenuCategoriesQueryVariables>(GetMenuCategoriesDocument, options);
        }
export type GetMenuCategoriesQueryHookResult = ReturnType<typeof useGetMenuCategoriesQuery>;
export type GetMenuCategoriesLazyQueryHookResult = ReturnType<typeof useGetMenuCategoriesLazyQuery>;
export type GetMenuCategoriesSuspenseQueryHookResult = ReturnType<typeof useGetMenuCategoriesSuspenseQuery>;
export type GetMenuCategoriesQueryResult = Apollo.QueryResult<GetMenuCategoriesQuery, GetMenuCategoriesQueryVariables>;
export const CloneMenuItemDocument = gql`
    mutation CloneMenuItem($cloneMenuItemId: Int!, $input: MenuItemInput!) {
  cloneMenuItem(cloneMenuItemId: $cloneMenuItemId, input: $input) {
    id
  }
}
    `;
export type CloneMenuItemMutationFn = Apollo.MutationFunction<CloneMenuItemMutation, CloneMenuItemMutationVariables>;

/**
 * __useCloneMenuItemMutation__
 *
 * To run a mutation, you first call `useCloneMenuItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCloneMenuItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cloneMenuItemMutation, { data, loading, error }] = useCloneMenuItemMutation({
 *   variables: {
 *      cloneMenuItemId: // value for 'cloneMenuItemId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCloneMenuItemMutation(baseOptions?: Apollo.MutationHookOptions<CloneMenuItemMutation, CloneMenuItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CloneMenuItemMutation, CloneMenuItemMutationVariables>(CloneMenuItemDocument, options);
      }
export type CloneMenuItemMutationHookResult = ReturnType<typeof useCloneMenuItemMutation>;
export type CloneMenuItemMutationResult = Apollo.MutationResult<CloneMenuItemMutation>;
export type CloneMenuItemMutationOptions = Apollo.BaseMutationOptions<CloneMenuItemMutation, CloneMenuItemMutationVariables>;
export const DeleteMenuItemDocument = gql`
    mutation DeleteMenuItem($id: Int!) {
  deleteMenuItem(id: $id)
}
    `;
export type DeleteMenuItemMutationFn = Apollo.MutationFunction<DeleteMenuItemMutation, DeleteMenuItemMutationVariables>;

/**
 * __useDeleteMenuItemMutation__
 *
 * To run a mutation, you first call `useDeleteMenuItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMenuItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMenuItemMutation, { data, loading, error }] = useDeleteMenuItemMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteMenuItemMutation(baseOptions?: Apollo.MutationHookOptions<DeleteMenuItemMutation, DeleteMenuItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteMenuItemMutation, DeleteMenuItemMutationVariables>(DeleteMenuItemDocument, options);
      }
export type DeleteMenuItemMutationHookResult = ReturnType<typeof useDeleteMenuItemMutation>;
export type DeleteMenuItemMutationResult = Apollo.MutationResult<DeleteMenuItemMutation>;
export type DeleteMenuItemMutationOptions = Apollo.BaseMutationOptions<DeleteMenuItemMutation, DeleteMenuItemMutationVariables>;
export const CreateMenuCategoryDocument = gql`
    mutation CreateMenuCategory($companyId: Int!, $input: MenuCategoryInput!) {
  createMenuCategory(companyId: $companyId, input: $input) {
    id
    company {
      id
      name
    }
    name
    description
    favicon
    numberOfItems
    deleted
  }
}
    `;
export type CreateMenuCategoryMutationFn = Apollo.MutationFunction<CreateMenuCategoryMutation, CreateMenuCategoryMutationVariables>;

/**
 * __useCreateMenuCategoryMutation__
 *
 * To run a mutation, you first call `useCreateMenuCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMenuCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMenuCategoryMutation, { data, loading, error }] = useCreateMenuCategoryMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMenuCategoryMutation(baseOptions?: Apollo.MutationHookOptions<CreateMenuCategoryMutation, CreateMenuCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateMenuCategoryMutation, CreateMenuCategoryMutationVariables>(CreateMenuCategoryDocument, options);
      }
export type CreateMenuCategoryMutationHookResult = ReturnType<typeof useCreateMenuCategoryMutation>;
export type CreateMenuCategoryMutationResult = Apollo.MutationResult<CreateMenuCategoryMutation>;
export type CreateMenuCategoryMutationOptions = Apollo.BaseMutationOptions<CreateMenuCategoryMutation, CreateMenuCategoryMutationVariables>;
export const GetAdditivesTableMenuSupplementsDocument = gql`
    query GetAdditivesTableMenuSupplements($page: Int, $itemsPerPage: Int, $id: Int, $search: String, $isDeleted: Boolean) {
  user {
    company {
      menuSupplements(
        itemsPerPage: $itemsPerPage
        page: $page
        search: $search
        isDeleted: $isDeleted
        id: $id
      ) {
        supplements {
          id
          groupName
          name
          numberOfOption
          isRequireOnOrder
          maxCountOnOrder
          price
          deleted
        }
        totalCount
        currentPage
        itemsPerPage
        pageCount
      }
    }
  }
}
    `;

/**
 * __useGetAdditivesTableMenuSupplementsQuery__
 *
 * To run a query within a React component, call `useGetAdditivesTableMenuSupplementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdditivesTableMenuSupplementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdditivesTableMenuSupplementsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      itemsPerPage: // value for 'itemsPerPage'
 *      id: // value for 'id'
 *      search: // value for 'search'
 *      isDeleted: // value for 'isDeleted'
 *   },
 * });
 */
export function useGetAdditivesTableMenuSupplementsQuery(baseOptions?: Apollo.QueryHookOptions<GetAdditivesTableMenuSupplementsQuery, GetAdditivesTableMenuSupplementsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAdditivesTableMenuSupplementsQuery, GetAdditivesTableMenuSupplementsQueryVariables>(GetAdditivesTableMenuSupplementsDocument, options);
      }
export function useGetAdditivesTableMenuSupplementsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAdditivesTableMenuSupplementsQuery, GetAdditivesTableMenuSupplementsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAdditivesTableMenuSupplementsQuery, GetAdditivesTableMenuSupplementsQueryVariables>(GetAdditivesTableMenuSupplementsDocument, options);
        }
export function useGetAdditivesTableMenuSupplementsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetAdditivesTableMenuSupplementsQuery, GetAdditivesTableMenuSupplementsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAdditivesTableMenuSupplementsQuery, GetAdditivesTableMenuSupplementsQueryVariables>(GetAdditivesTableMenuSupplementsDocument, options);
        }
export type GetAdditivesTableMenuSupplementsQueryHookResult = ReturnType<typeof useGetAdditivesTableMenuSupplementsQuery>;
export type GetAdditivesTableMenuSupplementsLazyQueryHookResult = ReturnType<typeof useGetAdditivesTableMenuSupplementsLazyQuery>;
export type GetAdditivesTableMenuSupplementsSuspenseQueryHookResult = ReturnType<typeof useGetAdditivesTableMenuSupplementsSuspenseQuery>;
export type GetAdditivesTableMenuSupplementsQueryResult = Apollo.QueryResult<GetAdditivesTableMenuSupplementsQuery, GetAdditivesTableMenuSupplementsQueryVariables>;
export const GetMenuPageDocument = gql`
    query GetMenuPage($page: Int, $itemsPerPage: Int, $id: Int, $search: String, $menuTypeId: Int, $isDeleted: Boolean) {
  user {
    currentBranch {
      menus(
        page: $page
        itemsPerPage: $itemsPerPage
        id: $id
        search: $search
        menuTypeId: $menuTypeId
        isDeleted: $isDeleted
      ) {
        menus {
          id
          branch {
            id
            country {
              id
            }
          }
          name
          type {
            id
          }
          status {
            id
            name
            slug
          }
          numberOfCategories
          numberOfItems
          categoryRelations {
            id
          }
          deleted
        }
        totalCount
        currentPage
        itemsPerPage
        pageCount
      }
    }
  }
}
    `;

/**
 * __useGetMenuPageQuery__
 *
 * To run a query within a React component, call `useGetMenuPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMenuPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMenuPageQuery({
 *   variables: {
 *      page: // value for 'page'
 *      itemsPerPage: // value for 'itemsPerPage'
 *      id: // value for 'id'
 *      search: // value for 'search'
 *      menuTypeId: // value for 'menuTypeId'
 *      isDeleted: // value for 'isDeleted'
 *   },
 * });
 */
export function useGetMenuPageQuery(baseOptions?: Apollo.QueryHookOptions<GetMenuPageQuery, GetMenuPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMenuPageQuery, GetMenuPageQueryVariables>(GetMenuPageDocument, options);
      }
export function useGetMenuPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMenuPageQuery, GetMenuPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMenuPageQuery, GetMenuPageQueryVariables>(GetMenuPageDocument, options);
        }
export function useGetMenuPageSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetMenuPageQuery, GetMenuPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetMenuPageQuery, GetMenuPageQueryVariables>(GetMenuPageDocument, options);
        }
export type GetMenuPageQueryHookResult = ReturnType<typeof useGetMenuPageQuery>;
export type GetMenuPageLazyQueryHookResult = ReturnType<typeof useGetMenuPageLazyQuery>;
export type GetMenuPageSuspenseQueryHookResult = ReturnType<typeof useGetMenuPageSuspenseQuery>;
export type GetMenuPageQueryResult = Apollo.QueryResult<GetMenuPageQuery, GetMenuPageQueryVariables>;
export type GetMenuItemsQueryVariables = Types.Exact<{
  itemsPerPage: Types.Scalars['Int']['input'];
  page: Types.Scalars['Int']['input'];
  sortBy?: Types.InputMaybe<Types.Scalars['String']['input']>;
  order?: Types.InputMaybe<Types.Scalars['String']['input']>;
  id?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  search?: Types.InputMaybe<Types.Scalars['String']['input']>;
  menuCategoryId?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  isDeleted?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;


export type GetMenuItemsQuery = { __typename?: 'Query', user?: { __typename?: 'UserProxy', company?: { __typename?: 'CompanyProxy', menuItems?: { __typename?: 'MenuItemPaginated', totalCount?: number | null, currentPage?: number | null, itemsPerPage?: number | null, pageCount?: string | null, items?: Array<{ __typename?: 'MenuItemProxy', id: number, name: string, description?: string | null, categories?: Array<{ __typename?: 'MenuCategoryProxy', id: number, name: string, subcategories?: Array<{ __typename?: 'MenuCategoryProxy', id: number, name: string }> | null }> | null, portions?: Array<{ __typename?: 'MenuItemPortionProxy', price: number, volume: number, name: string }> | null }> | null } | null } | null } | null };

export type GetMenuAllergensQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetMenuAllergensQuery = { __typename?: 'Query', menuAllergens: Array<{ __typename?: 'MenuAllergenProxy', id: number, name: string, slug: string }> };

export type GetMenuTypesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetMenuTypesQuery = { __typename?: 'Query', menuTypes: Array<{ __typename?: 'MenuTypeProxy', id: number, name: string, slug: string }> };

export type GetMenuMarkersQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetMenuMarkersQuery = { __typename?: 'Query', menuMarkers: Array<{ __typename?: 'MenuMarkerProxy', id: number, name: string, slug: string }> };

export type GetMenuStatusesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetMenuStatusesQuery = { __typename?: 'Query', menuStatuses: Array<{ __typename?: 'MenuStatusProxy', id: number, name: string, slug: string }> };

export type GetMenuUnitsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetMenuUnitsQuery = { __typename?: 'Query', relations: { __typename?: 'RelationProxy', units?: Array<{ __typename?: 'UnitProxy', id: number, name: string }> | null } };

export type CreateMenuItemMutationVariables = Types.Exact<{
  companyId: Types.Scalars['Int']['input'];
  name: Types.Scalars['String']['input'];
  types: Array<Types.Scalars['Int']['input']> | Types.Scalars['Int']['input'];
  categories: Array<Types.Scalars['Int']['input']> | Types.Scalars['Int']['input'];
  description?: Types.InputMaybe<Types.Scalars['String']['input']>;
  composition?: Types.InputMaybe<Types.Scalars['String']['input']>;
  unit: Types.Scalars['Int']['input'];
  portions: Array<Types.MenuItemPortionInput> | Types.MenuItemPortionInput;
  cookingTime?: Types.InputMaybe<Types.Scalars['String']['input']>;
  isLongCookingTime?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  markers?: Types.InputMaybe<Array<Types.Scalars['Int']['input']> | Types.Scalars['Int']['input']>;
  allergens?: Types.InputMaybe<Array<Types.Scalars['Int']['input']> | Types.Scalars['Int']['input']>;
}>;


export type CreateMenuItemMutation = { __typename?: 'Mutation', createMenuItem: { __typename?: 'MenuItemProxy', name: string, id: number, description?: string | null, composition?: string | null, allergens?: Array<{ __typename?: 'MenuAllergenProxy', id: number, label: number, name: string }> | null, categories?: Array<{ __typename?: 'MenuCategoryProxy', id: number, name: string }> | null, types?: Array<{ __typename?: 'MenuTypeProxy', id: number, name: string }> | null, unit: { __typename?: 'UnitProxy', id: number, name: string }, portions?: Array<{ __typename?: 'MenuItemPortionProxy', id: number, price: number, name: string, volume: number }> | null, supplements?: Array<{ __typename?: 'MenuSupplementProxy', id: number, name?: string | null }> | null } };

export type GetMenuSupplementsQueryVariables = Types.Exact<{
  search?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type GetMenuSupplementsQuery = { __typename?: 'Query', user?: { __typename?: 'UserProxy', company?: { __typename?: 'CompanyProxy', menuSupplements?: { __typename?: 'MenuSupplementPaginated', supplements?: Array<{ __typename?: 'MenuSupplementProxy', name?: string | null, groupName?: string | null, id: number, options?: Array<{ __typename?: 'MenuSupplementProxy', id: number, name?: string | null }> | null }> | null } | null } | null } | null };

export type GetCurrentMenuItemQueryVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type GetCurrentMenuItemQuery = { __typename?: 'Query', user?: { __typename?: 'UserProxy', company?: { __typename?: 'CompanyProxy', menuItems?: { __typename?: 'MenuItemPaginated', items?: Array<{ __typename?: 'MenuItemProxy', id: number, name: string, description?: string | null, composition?: string | null, cookingTime?: string | null, isLongCookingTime: boolean, types?: Array<{ __typename?: 'MenuTypeProxy', id: number, name: string }> | null, categories?: Array<{ __typename?: 'MenuCategoryProxy', id: number, name: string, description?: string | null, parent?: { __typename?: 'MenuCategoryProxy', name: string } | null, subcategories?: Array<{ __typename?: 'MenuCategoryProxy', name: string }> | null }> | null, unit: { __typename?: 'UnitProxy', id: number, name: string }, portions?: Array<{ __typename?: 'MenuItemPortionProxy', id: number, name: string, volume: number, price: number }> | null, markers?: Array<{ __typename?: 'MenuMarkerProxy', id: number, name: string }> | null, allergens?: Array<{ __typename?: 'MenuAllergenProxy', id: number, name: string }> | null }> | null } | null } | null } | null };

export type GetMenuCategoriesQueryVariables = Types.Exact<{
  page?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  itemsPerPage?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  id?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  search?: Types.InputMaybe<Types.Scalars['String']['input']>;
  isDeleted?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;


export type GetMenuCategoriesQuery = { __typename?: 'Query', user?: { __typename?: 'UserProxy', company?: { __typename?: 'CompanyProxy', menuCategories?: { __typename?: 'MenuCategoryPaginated', totalCount?: number | null, currentPage?: number | null, itemsPerPage?: number | null, pageCount?: string | null, categories?: Array<{ __typename?: 'MenuCategoryProxy', id: number, name: string, description?: string | null, favicon?: string | null, numberOfItems: number, deleted: boolean, parent?: { __typename?: 'MenuCategoryProxy', id: number, name: string, description?: string | null } | null, subcategories?: Array<{ __typename?: 'MenuCategoryProxy', id: number, name: string, description?: string | null, numberOfItems: number }> | null }> | null } | null } | null } | null };

export type CloneMenuItemMutationVariables = Types.Exact<{
  cloneMenuItemId: Types.Scalars['Int']['input'];
  input: Types.MenuItemInput;
}>;


export type CloneMenuItemMutation = { __typename?: 'Mutation', cloneMenuItem: { __typename?: 'MenuItemProxy', id: number } };

export type DeleteMenuItemMutationVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type DeleteMenuItemMutation = { __typename?: 'Mutation', deleteMenuItem: boolean };

export type CreateMenuCategoryMutationVariables = Types.Exact<{
  companyId: Types.Scalars['Int']['input'];
  input: Types.MenuCategoryInput;
}>;


export type CreateMenuCategoryMutation = { __typename?: 'Mutation', createMenuCategory: { __typename?: 'MenuCategoryProxy', id: number, name: string, description?: string | null, favicon?: string | null, numberOfItems: number, deleted: boolean, company: { __typename?: 'CompanyProxy', id: number, name: string } } };

export type GetAdditivesTableMenuSupplementsQueryVariables = Types.Exact<{
  page?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  itemsPerPage?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  id?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  search?: Types.InputMaybe<Types.Scalars['String']['input']>;
  isDeleted?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;


export type GetAdditivesTableMenuSupplementsQuery = { __typename?: 'Query', user?: { __typename?: 'UserProxy', company?: { __typename?: 'CompanyProxy', menuSupplements?: { __typename?: 'MenuSupplementPaginated', totalCount?: number | null, currentPage?: number | null, itemsPerPage?: number | null, pageCount?: string | null, supplements?: Array<{ __typename?: 'MenuSupplementProxy', id: number, groupName?: string | null, name?: string | null, numberOfOption?: number | null, isRequireOnOrder?: boolean | null, maxCountOnOrder?: number | null, price?: number | null, deleted: boolean }> | null } | null } | null } | null };

export type GetMenuPageQueryVariables = Types.Exact<{
  page?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  itemsPerPage?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  id?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  search?: Types.InputMaybe<Types.Scalars['String']['input']>;
  menuTypeId?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  isDeleted?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;


export type GetMenuPageQuery = { __typename?: 'Query', user?: { __typename?: 'UserProxy', currentBranch?: { __typename?: 'BranchProxy', menus?: { __typename?: 'MenuPaginated', totalCount?: number | null, currentPage?: number | null, itemsPerPage?: number | null, pageCount?: string | null, menus?: Array<{ __typename?: 'MenuProxy', id: number, name: string, numberOfCategories: number, numberOfItems: number, deleted: boolean, branch: { __typename?: 'BranchProxy', id: number, country: { __typename?: 'Country', id: string } }, type: { __typename?: 'MenuTypeProxy', id: number }, status: { __typename?: 'MenuStatusProxy', id: number, name: string, slug: string }, categoryRelations?: Array<{ __typename?: 'MenuCategoryRelationProxy', id: number }> | null }> | null } | null } | null } | null };
