import React from 'react';
import Select, { StylesConfig, OptionTypeBase } from "react-select";
import { useFormikContext } from 'formik';
import customStyles from "./customStyles";
import {defaultProps} from "react-select/base";

interface Option {
    value: string | number;
    label: string;
}

interface ReactSelectProps {
    id?: string
    value: Option | null,
    options: Array<any>,
    placeholder: string,
    onChange: (option: Option | null) => void,
    name?: string,
    isSearchable?: boolean,
    disabled?: boolean,
    key?: string,
    isMeMenu? :boolean,
    isTitle?: boolean,
    isMulti?: boolean,
}

const filterOption = (option: Option, inputValue: string) => {
    return option.label.toLowerCase().includes(inputValue.toLowerCase());
};

const ReactSelect: React.FC<ReactSelectProps> = ({id, value, options, placeholder, onChange, isSearchable, disabled = false, key, name, isMeMenu, isTitle, isMulti = false}) => {
    const formikContext = useFormikContext<any>();
    const errors = formikContext?.errors && formikContext.errors[name];
    const touched = formikContext?.touched && formikContext.touched[name];

    return (
        <div>
            <Select
                id={id}
                key={key}
                name={name}
                value={value}
                options={options}
                placeholder={placeholder}
                onChange={onChange}
                isSearchable={isSearchable}
                styles={customStyles(errors, touched, isMeMenu, isTitle, isMulti)}
                isDisabled={disabled}
                filterOption={filterOption}
                isMulti={isMulti}
            />
        </div>
    );
};

export default ReactSelect;
