export interface IStep {
    key: string;
    label: string;
}

export const getSteps = (t: any): IStep[] => [
    { key: 'menu', label: t('menu') },
    { key: 'category', label: t('Category') },
    { key: 'dish', label: t('Dishes') },
    { key: 'view', label: t('Preview') },
    { key: 'generate', label: t('Code generation') },

]