/* eslint-disable */
import * as Types from '../../../../graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const GetAllClientsHistoryVisitDocument = gql`
    query getAllClientsHistoryVisit($id: Int, $itemsPerPage: Int, $page: Int, $statusId: Int, $search: String, $serviceId: Int, $employeeId: Int, $startDate: String, $endDate: String) {
  user {
    company {
      branchesPaginated(id: $id) {
        branches {
          bookings(
            itemsPerPage: $itemsPerPage
            page: $page
            statusId: $statusId
            serviceId: $serviceId
            employeeId: $employeeId
            startDate: $startDate
            endDate: $endDate
            clientInputType: {search: $search}
          ) {
            itemsPerPage
            totalCount
            pageCount
            bookings {
              id
              date
              time
              service {
                name
                price
              }
              employee {
                name
                surname
              }
              client {
                name
                surname
                email
                phone
              }
              status {
                name
                colorCode
              }
              review {
                rating
                id
              }
            }
          }
        }
        itemsPerPage
      }
    }
  }
}
    `;

/**
 * __useGetAllClientsHistoryVisitQuery__
 *
 * To run a query within a React component, call `useGetAllClientsHistoryVisitQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllClientsHistoryVisitQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllClientsHistoryVisitQuery({
 *   variables: {
 *      id: // value for 'id'
 *      itemsPerPage: // value for 'itemsPerPage'
 *      page: // value for 'page'
 *      statusId: // value for 'statusId'
 *      search: // value for 'search'
 *      serviceId: // value for 'serviceId'
 *      employeeId: // value for 'employeeId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useGetAllClientsHistoryVisitQuery(baseOptions?: Apollo.QueryHookOptions<GetAllClientsHistoryVisitQuery, GetAllClientsHistoryVisitQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllClientsHistoryVisitQuery, GetAllClientsHistoryVisitQueryVariables>(GetAllClientsHistoryVisitDocument, options);
      }
export function useGetAllClientsHistoryVisitLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllClientsHistoryVisitQuery, GetAllClientsHistoryVisitQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllClientsHistoryVisitQuery, GetAllClientsHistoryVisitQueryVariables>(GetAllClientsHistoryVisitDocument, options);
        }
export function useGetAllClientsHistoryVisitSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetAllClientsHistoryVisitQuery, GetAllClientsHistoryVisitQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAllClientsHistoryVisitQuery, GetAllClientsHistoryVisitQueryVariables>(GetAllClientsHistoryVisitDocument, options);
        }
export type GetAllClientsHistoryVisitQueryHookResult = ReturnType<typeof useGetAllClientsHistoryVisitQuery>;
export type GetAllClientsHistoryVisitLazyQueryHookResult = ReturnType<typeof useGetAllClientsHistoryVisitLazyQuery>;
export type GetAllClientsHistoryVisitSuspenseQueryHookResult = ReturnType<typeof useGetAllClientsHistoryVisitSuspenseQuery>;
export type GetAllClientsHistoryVisitQueryResult = Apollo.QueryResult<GetAllClientsHistoryVisitQuery, GetAllClientsHistoryVisitQueryVariables>;
export const GetBookingStatusDocument = gql`
    query getBookingStatus {
  bookingStatus {
    id
    name
  }
}
    `;

/**
 * __useGetBookingStatusQuery__
 *
 * To run a query within a React component, call `useGetBookingStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBookingStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBookingStatusQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBookingStatusQuery(baseOptions?: Apollo.QueryHookOptions<GetBookingStatusQuery, GetBookingStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBookingStatusQuery, GetBookingStatusQueryVariables>(GetBookingStatusDocument, options);
      }
export function useGetBookingStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBookingStatusQuery, GetBookingStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBookingStatusQuery, GetBookingStatusQueryVariables>(GetBookingStatusDocument, options);
        }
export function useGetBookingStatusSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetBookingStatusQuery, GetBookingStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetBookingStatusQuery, GetBookingStatusQueryVariables>(GetBookingStatusDocument, options);
        }
export type GetBookingStatusQueryHookResult = ReturnType<typeof useGetBookingStatusQuery>;
export type GetBookingStatusLazyQueryHookResult = ReturnType<typeof useGetBookingStatusLazyQuery>;
export type GetBookingStatusSuspenseQueryHookResult = ReturnType<typeof useGetBookingStatusSuspenseQuery>;
export type GetBookingStatusQueryResult = Apollo.QueryResult<GetBookingStatusQuery, GetBookingStatusQueryVariables>;
export const GetServicesForClientsDocument = gql`
    query getServicesForClients($onlyFromBookings: Boolean, $itemsPerPage: Int) {
  user {
    company {
      services(onlyFromBookings: $onlyFromBookings, itemsPerPage: $itemsPerPage) {
        itemsPerPage
        services {
          id
          name
          employeeServiceRelations {
            employee {
              id
              name
              surname
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetServicesForClientsQuery__
 *
 * To run a query within a React component, call `useGetServicesForClientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetServicesForClientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetServicesForClientsQuery({
 *   variables: {
 *      onlyFromBookings: // value for 'onlyFromBookings'
 *      itemsPerPage: // value for 'itemsPerPage'
 *   },
 * });
 */
export function useGetServicesForClientsQuery(baseOptions?: Apollo.QueryHookOptions<GetServicesForClientsQuery, GetServicesForClientsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetServicesForClientsQuery, GetServicesForClientsQueryVariables>(GetServicesForClientsDocument, options);
      }
export function useGetServicesForClientsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetServicesForClientsQuery, GetServicesForClientsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetServicesForClientsQuery, GetServicesForClientsQueryVariables>(GetServicesForClientsDocument, options);
        }
export function useGetServicesForClientsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetServicesForClientsQuery, GetServicesForClientsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetServicesForClientsQuery, GetServicesForClientsQueryVariables>(GetServicesForClientsDocument, options);
        }
export type GetServicesForClientsQueryHookResult = ReturnType<typeof useGetServicesForClientsQuery>;
export type GetServicesForClientsLazyQueryHookResult = ReturnType<typeof useGetServicesForClientsLazyQuery>;
export type GetServicesForClientsSuspenseQueryHookResult = ReturnType<typeof useGetServicesForClientsSuspenseQuery>;
export type GetServicesForClientsQueryResult = Apollo.QueryResult<GetServicesForClientsQuery, GetServicesForClientsQueryVariables>;
export const GetEmployeesDocument = gql`
    query getEmployees($onlyActive: Boolean, $itemsPerPage: Int) {
  user {
    company {
      employees(onlyActive: $onlyActive, itemsPerPage: $itemsPerPage) {
        itemsPerPage
        employees {
          id
          name
          surname
          employeeServiceRelations {
            service {
              id
              name
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetEmployeesQuery__
 *
 * To run a query within a React component, call `useGetEmployeesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmployeesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmployeesQuery({
 *   variables: {
 *      onlyActive: // value for 'onlyActive'
 *      itemsPerPage: // value for 'itemsPerPage'
 *   },
 * });
 */
export function useGetEmployeesQuery(baseOptions?: Apollo.QueryHookOptions<GetEmployeesQuery, GetEmployeesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEmployeesQuery, GetEmployeesQueryVariables>(GetEmployeesDocument, options);
      }
export function useGetEmployeesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEmployeesQuery, GetEmployeesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEmployeesQuery, GetEmployeesQueryVariables>(GetEmployeesDocument, options);
        }
export function useGetEmployeesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetEmployeesQuery, GetEmployeesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetEmployeesQuery, GetEmployeesQueryVariables>(GetEmployeesDocument, options);
        }
export type GetEmployeesQueryHookResult = ReturnType<typeof useGetEmployeesQuery>;
export type GetEmployeesLazyQueryHookResult = ReturnType<typeof useGetEmployeesLazyQuery>;
export type GetEmployeesSuspenseQueryHookResult = ReturnType<typeof useGetEmployeesSuspenseQuery>;
export type GetEmployeesQueryResult = Apollo.QueryResult<GetEmployeesQuery, GetEmployeesQueryVariables>;
export const GetOneReviewDocument = gql`
    query getOneReview($id: Int) {
  user {
    company {
      reviews(id: $id) {
        reviews {
          id
          name
          text
          rating
          employee {
            name
            surname
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetOneReviewQuery__
 *
 * To run a query within a React component, call `useGetOneReviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOneReviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOneReviewQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetOneReviewQuery(baseOptions?: Apollo.QueryHookOptions<GetOneReviewQuery, GetOneReviewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOneReviewQuery, GetOneReviewQueryVariables>(GetOneReviewDocument, options);
      }
export function useGetOneReviewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOneReviewQuery, GetOneReviewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOneReviewQuery, GetOneReviewQueryVariables>(GetOneReviewDocument, options);
        }
export function useGetOneReviewSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetOneReviewQuery, GetOneReviewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetOneReviewQuery, GetOneReviewQueryVariables>(GetOneReviewDocument, options);
        }
export type GetOneReviewQueryHookResult = ReturnType<typeof useGetOneReviewQuery>;
export type GetOneReviewLazyQueryHookResult = ReturnType<typeof useGetOneReviewLazyQuery>;
export type GetOneReviewSuspenseQueryHookResult = ReturnType<typeof useGetOneReviewSuspenseQuery>;
export type GetOneReviewQueryResult = Apollo.QueryResult<GetOneReviewQuery, GetOneReviewQueryVariables>;
export const UpdateReviewDocument = gql`
    mutation UpdateReview($id: Int!, $name: String!, $text: String, $rating: Int!) {
  updateReview(id: $id, input: {name: $name, rating: $rating, text: $text}) {
    id
    name
    text
    rating
  }
}
    `;
export type UpdateReviewMutationFn = Apollo.MutationFunction<UpdateReviewMutation, UpdateReviewMutationVariables>;

/**
 * __useUpdateReviewMutation__
 *
 * To run a mutation, you first call `useUpdateReviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateReviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateReviewMutation, { data, loading, error }] = useUpdateReviewMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      text: // value for 'text'
 *      rating: // value for 'rating'
 *   },
 * });
 */
export function useUpdateReviewMutation(baseOptions?: Apollo.MutationHookOptions<UpdateReviewMutation, UpdateReviewMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateReviewMutation, UpdateReviewMutationVariables>(UpdateReviewDocument, options);
      }
export type UpdateReviewMutationHookResult = ReturnType<typeof useUpdateReviewMutation>;
export type UpdateReviewMutationResult = Apollo.MutationResult<UpdateReviewMutation>;
export type UpdateReviewMutationOptions = Apollo.BaseMutationOptions<UpdateReviewMutation, UpdateReviewMutationVariables>;
export type GetAllClientsHistoryVisitQueryVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  itemsPerPage?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  page?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  statusId?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  search?: Types.InputMaybe<Types.Scalars['String']['input']>;
  serviceId?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  employeeId?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  startDate?: Types.InputMaybe<Types.Scalars['String']['input']>;
  endDate?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type GetAllClientsHistoryVisitQuery = { __typename?: 'Query', user?: { __typename?: 'UserProxy', company?: { __typename?: 'CompanyProxy', branchesPaginated?: { __typename?: 'BranchPaginated', itemsPerPage?: number | null, branches?: Array<{ __typename?: 'BranchProxy', bookings?: { __typename?: 'BookingPaginated', itemsPerPage?: number | null, totalCount?: number | null, pageCount?: string | null, bookings?: Array<{ __typename?: 'BookingMainProxy', id: number, date?: string | null, time?: string | null, service?: { __typename?: 'ServiceProxy', name: string, price?: number | null } | null, employee?: { __typename?: 'EmployeeProxy', name?: string | null, surname?: string | null } | null, client?: { __typename?: 'ClientProxy', name?: string | null, surname?: string | null, email?: string | null, phone?: string | null } | null, status?: { __typename?: 'BookingStatusProxy', name: string, colorCode?: string | null } | null, review?: { __typename?: 'ReviewProxy', rating: number, id: number } | null }> | null } | null }> | null } | null } | null } | null };

export type GetBookingStatusQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetBookingStatusQuery = { __typename?: 'Query', bookingStatus: Array<{ __typename?: 'BookingStatusProxy', id: number, name: string }> };

export type GetServicesForClientsQueryVariables = Types.Exact<{
  onlyFromBookings?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  itemsPerPage?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type GetServicesForClientsQuery = { __typename?: 'Query', user?: { __typename?: 'UserProxy', company?: { __typename?: 'CompanyProxy', services?: { __typename?: 'ServicePaginated', itemsPerPage?: number | null, services?: Array<{ __typename?: 'ServiceProxy', id: number, name: string, employeeServiceRelations?: Array<{ __typename?: 'EmployeeServiceRelationProxy', employee?: { __typename?: 'EmployeeProxy', id: number, name?: string | null, surname?: string | null } | null }> | null }> | null } | null } | null } | null };

export type GetEmployeesQueryVariables = Types.Exact<{
  onlyActive?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  itemsPerPage?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type GetEmployeesQuery = { __typename?: 'Query', user?: { __typename?: 'UserProxy', company?: { __typename?: 'CompanyProxy', employees?: { __typename?: 'EmployeePaginated', itemsPerPage?: number | null, employees?: Array<{ __typename?: 'EmployeeProxy', id: number, name?: string | null, surname?: string | null, employeeServiceRelations?: Array<{ __typename?: 'EmployeeServiceRelationProxy', service?: { __typename?: 'ServiceProxy', id: number, name: string } | null }> | null }> | null } | null } | null } | null };

export type GetOneReviewQueryVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type GetOneReviewQuery = { __typename?: 'Query', user?: { __typename?: 'UserProxy', company?: { __typename?: 'CompanyProxy', reviews?: { __typename?: 'ReviewPaginated', reviews?: Array<{ __typename?: 'ReviewProxy', id: number, name: string, text?: string | null, rating: number, employee?: { __typename?: 'EmployeeProxy', name?: string | null, surname?: string | null } | null }> | null } | null } | null } | null };

export type UpdateReviewMutationVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
  name: Types.Scalars['String']['input'];
  text?: Types.InputMaybe<Types.Scalars['String']['input']>;
  rating: Types.Scalars['Int']['input'];
}>;


export type UpdateReviewMutation = { __typename?: 'Mutation', updateReview: { __typename?: 'ReviewProxy', id: number, name: string, text?: string | null, rating: number } };
