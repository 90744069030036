import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

interface ConfirmDeleteModalProps {
    show: boolean;
    handleClose: () => void;
    onConfirm: () => void;
    groupName: string;
}

const ConfirmDeleteModal: React.FC<ConfirmDeleteModalProps> = (
    {
        show,
        handleClose,
        onConfirm,
        groupName,
    }) => {
    const {t} = useTranslation();

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>{t('Confirm Deletion')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {t('Are you sure you want to delete the additive group "{{groupName}}"?', {
                    groupName,
                })}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    {t('Cancel')}
                </Button>
                <Button variant="danger" onClick={onConfirm}>
                    {t('Delete')}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ConfirmDeleteModal;
