import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, ListGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

interface Category {
    id: number;
    name: string;
    subcategories: Category[];
}

interface MoveCategoryModalProps {
    show: boolean;
    handleClose: () => void;
    categoryToMove: Category;
    categories: Category[]; // List of categories to choose from
    onMove: (newParentId: number | null) => void; // Callback when the category is moved
}

const MoveCategoryModal: React.FC<MoveCategoryModalProps> = ({
                                                                 show,
                                                                 handleClose,
                                                                 categoryToMove,
                                                                 categories,
                                                                 onMove,
                                                             }) => {
    const { t } = useTranslation();

    const [searchTerm, setSearchTerm] = useState<string>('');
    const [filteredCategories, setFilteredCategories] = useState<Category[]>(categories);
    const [selectedCategoryId, setSelectedCategoryId] = useState<number | null>(null);

    useEffect(() => {
        // Filter categories based on search term
        const filterCategories = (categories: Category[]): Category[] => {
            return categories.filter((cat) =>
                cat.name.toLowerCase().includes(searchTerm.toLowerCase())
            );
        };

        setFilteredCategories(filterCategories(categories));
    }, [searchTerm, categories]);

    const handleSubmit = () => {
        // Handle move logic
        // For now, just call onMove with the selectedCategoryId
        onMove(selectedCategoryId);
        handleClose();
    };

    const title = t('Move Category {{categoryName}}', { categoryName: categoryToMove.name });

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group controlId="searchCategories">
                    <Form.Control
                        type="text"
                        placeholder={t('Search by category name')}
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                </Form.Group>
                <div className="mt-3" style={{ maxHeight: '300px', overflowY: 'auto' }}>
                    <ListGroup>
                        {/* Option to move to root */}
                        <ListGroup.Item
                            action
                            active={selectedCategoryId === null}
                            onClick={() => setSelectedCategoryId(null)}
                        >
                            {t('Root')}
                        </ListGroup.Item>
                        {filteredCategories.map((cat) => (
                            <ListGroup.Item
                                key={cat.id}
                                action
                                active={selectedCategoryId === cat.id}
                                onClick={() => setSelectedCategoryId(cat.id)}
                            >
                                {cat.name}
                            </ListGroup.Item>
                        ))}
                    </ListGroup>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={handleSubmit}>
                    {t('Move')}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default MoveCategoryModal;
