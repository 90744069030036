import React, { useState } from 'react';
import { Table, Button, OverlayTrigger, Tooltip, Row, Col, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Pagination, TableHeader } from '@services/ui-components';
import { useGetAdditivesTableMenuSupplementsQuery } from '@me-team/host/src/modules/menu/graphql/menu.hooks';
import AdditiveGroupFormModal from './AdditiveGroupFormModal';
import ConfirmDeleteModal from './ConfirmDeleteModal';

interface AdditiveGroup {
    id: number;
    groupName: string;
    items: { name: string }[]; // Dishes associated with the additive group
    numberOfOption: number;
    isRequireOnOrder: boolean;
    maxCountOnOrder: number;
    deleted: boolean;
}

interface FilterState {
    name: string;
    categoryId: string;
}

const AdditivesTable: React.FC = () => {
    const { t } = useTranslation();
    const [language, setLanguage] = useState<string>('en');
    const [showFilters, setShowFilters] = useState<boolean>(false);
    const [currentFilter, setCurrentFilter] = useState<FilterState>({ name: '', categoryId: '' });
    const [filter, setFilter] = useState<FilterState>({ name: '', categoryId: '' });
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [itemsPerPage] = useState<number>(20);
    // State for the modals
    const [showAdditiveGroupModal, setShowAdditiveGroupModal] = useState<boolean>(false);
    const [showEditModal, setShowEditModal] = useState<boolean>(false);
    const [editGroup, setEditGroup] = useState<AdditiveGroup | null>(null);
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const [deleteGroup, setDeleteGroup] = useState<AdditiveGroup | null>(null);

    // Toggle filters
    const toggleFilters = () => setShowFilters(!showFilters);

    // Handle language change (if needed)
    const handleLanguageChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setLanguage(e.target.value);
        // Additional logic to handle language change if necessary
    };

    // Fetch additive groups
    const { data, loading, error, refetch } = useGetAdditivesTableMenuSupplementsQuery({
        variables: {
            search: filter.name || null,
            id: filter.categoryId ? parseInt(filter.categoryId) : null,
            page: currentPage,
            itemsPerPage,
        },
    });

    if (loading) return <div>{t('Loading...')}</div>;
    if (error) return <div>{t('An error occurred:')} {error.message}</div>;

    const supplements = data?.user?.company?.menuSupplements?.supplements || [];

    const pageCount = parseInt(data?.user?.company?.menuSupplements?.pageCount || '0');
    const totalItems = data?.user?.company?.menuSupplements?.totalCount || 0;
    const firstItemIndex = (currentPage - 1) * itemsPerPage + 1;
    const lastItemIndex = Math.min(currentPage * itemsPerPage, totalItems);

    // Handle editing and deleting (archiving) additive groups
    const handleEditGroup = (group: AdditiveGroup) => {
        setEditGroup(group);
        setShowEditModal(true);
    };

    // Handle pagination
    const handlePageChange = (pageNumber: number) => {
        setCurrentPage(pageNumber);
        refetch();
    };

    // Handle filter apply and clear
    const handleApplyFilter = () => {
        setFilter(currentFilter);
        setCurrentPage(1);
        refetch();
    };

    const handleClearFilter = () => {
        setCurrentFilter({ name: '', categoryId: '' });
        setFilter({ name: '', categoryId: '' });
        setCurrentPage(1);
        refetch();
    };

    const handleArchiveGroup = (group: AdditiveGroup) => {
        setDeleteGroup(group);
        setShowDeleteModal(true);
    };

    const confirmDeleteGroup = () => {
        if (deleteGroup) {
            refetch();
        }
        setShowDeleteModal(false);
        setDeleteGroup(null);
    };

    const handleRestoreGroup = (group: AdditiveGroup) => {
        // Logic to restore the group
    };

    // Sort supplements to show deleted at the end
    const sortedSupplements = [...supplements].sort((a, b) => {
        if (a.deleted === b.deleted) return 0;
        return a.deleted ? 1 : -1;
    });

    const handleAddNewGroup = () => {
        setShowAdditiveGroupModal(true);
    };

    return (
        <div className="mb-4">
            <TableHeader
                title={t('Additive and Option Groups')}
                paths={[{ path: '/additives', label: t('Additives') }]}
                buttons={
                    <Row className="gx-3 justify-content-end">
                        <Col sm={6} lg={5} xxl={4}>
                            <Button
                                variant="primary"
                                className="fw-normal w-100 rounded-1"
                                onClick={handleAddNewGroup}
                            >
                                <i className="bi bi-plus-lg me-2"></i>
                                {t('Add New Group')}
                            </Button>
                        </Col>
                        <Col lg={6}>
                            <Button
                                className="btn btn-additional-violet fw-normal border-primary text-primary w-100"
                                onClick={toggleFilters}
                            >
                                <i className="bi bi-funnel me-2"></i>
                                {t('Filters')}
                            </Button>
                        </Col>
                    </Row>
                }
                showFilters={showFilters}
                colSize={7}
                btnColSize={5}
            >
                {/* Filters */}
                {showFilters && (
                    <Row className="gx-4 pb-6">
                        <Col lg={8} xl={9}>
                            <Row className="gx-3">
                                <Col md={6}>
                                    <div className="position-relative mb-3 mb-lg-0">
                                        <input
                                            type="text"
                                            className="form-control rounded-1"
                                            placeholder={t('Search by group name')}
                                            value={currentFilter.name}
                                            onChange={(e) =>
                                                setCurrentFilter({ ...currentFilter, name: e.target.value })
                                            }
                                        />
                                        <i className="bi bi-search position-absolute top-50 end-0 translate-middle-y text-dark me-3"></i>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <Form.Group controlId="categoryFilter">
                                        <Form.Control
                                            as="select"
                                            value={currentFilter.categoryId}
                                            onChange={(e) =>
                                                setCurrentFilter({ ...currentFilter, categoryId: e.target.value })
                                            }
                                        >
                                            <option value="">{t('Select Category')}</option>
                                            {/* Replace with actual categories */}
                                            <option value="1">{t('Pizza')}</option>
                                            <option value="2">{t('Pasta')}</option>
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Col>
                        <Col lg={4} xl={3}>
                            <Row className="gx-3 h-100">
                                <Col sm={6} className="mb-2 mb-sm-0">
                                    <Button
                                        variant="outline-dark"
                                        className="border-grey w-100 h-100 fw-normal rounded-1"
                                        onClick={handleClearFilter}
                                    >
                                        {t('Clear')}
                                    </Button>
                                </Col>
                                <Col sm={6}>
                                    <Button
                                        variant="primary"
                                        className="w-100 h-100 rounded-1 fw-normal px-2"
                                        onClick={handleApplyFilter}
                                    >
                                        {t('Apply')}
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                )}
            </TableHeader>
            {/* Additives Table */}
            <Table striped bordered hover responsive>
                <thead>
                <tr>
                    <th>{t('Group Name')}</th>
                    <th>{t('Dishes')}</th>
                    <th>{t('Number of Options')}</th>
                    <th>{t('Choice When Ordering')}</th>
                    <th>{t('Selectable Options')}</th>
                    <th>{t('Actions')}</th>
                </tr>
                </thead>
                <tbody>
                {sortedSupplements.length === 0 ? (
                    <tr>
                        <td colSpan={6}>{t('No additive groups found.')}</td>
                    </tr>
                ) : (
                    sortedSupplements.map((group: AdditiveGroup) => (
                        <tr key={group.id} className={group.deleted ? 'text-muted' : ''}>
                            <td>{group.groupName}</td>
                            <td>{group.items}</td>
                            <td>{group.numberOfOption}</td>
                            <td>
                                <OverlayTrigger
                                    overlay={
                                        <Tooltip>
                                            {t(
                                                'Mandatory or optional selection of additive/option when ordering a dish with this additive/option group'
                                            )}
                                        </Tooltip>
                                    }
                                >
                                    <Form.Check
                                        type="switch"
                                        id={`mandatory-switch-${group.id}`}
                                        checked={group.isRequireOnOrder}
                                        disabled
                                    />
                                </OverlayTrigger>
                            </td>
                            <td>{group.maxCountOnOrder}</td>
                            <td>
                                <div className="d-flex gap-2">
                                    {!group.deleted && (
                                        <>
                                            {/* Edit Button */}
                                            <OverlayTrigger overlay={<Tooltip>{t('Edit')}</Tooltip>}>
                                                <Button
                                                    variant="outline-primary"
                                                    onClick={() => handleEditGroup(group)}
                                                >
                                                    <i className="bi bi-pencil"></i>
                                                </Button>
                                            </OverlayTrigger>
                                            {/* Archive Button */}
                                            <OverlayTrigger overlay={<Tooltip>{t('Delete')}</Tooltip>}>
                                                <Button
                                                    variant="outline-danger"
                                                    onClick={() => handleArchiveGroup(group)}
                                                >
                                                    <i className="bi bi-trash"></i>
                                                </Button>
                                            </OverlayTrigger>
                                        </>
                                    )}
                                    {group.deleted && (
                                        <>
                                            {/* Restore Button */}
                                            <OverlayTrigger overlay={<Tooltip>{t('Restore')}</Tooltip>}>
                                                <Button
                                                    variant="outline-success"
                                                    onClick={() => handleRestoreGroup(group)}
                                                >
                                                    <i className="bi bi-arrow-clockwise"></i>
                                                </Button>
                                            </OverlayTrigger>
                                        </>
                                    )}
                                </div>
                            </td>
                        </tr>
                    ))
                )}
                </tbody>
            </Table>
            {/* Pagination */}
            {sortedSupplements.length > 0 && (
                <div className="d-flex justify-content-between align-items-center">
                    <Pagination
                        t={t}
                        pages={Number(pageCount)}
                        currentPage={currentPage}
                        onPageChange={handlePageChange}
                        firstItemIndex={firstItemIndex}
                        lastItemIndex={lastItemIndex}
                        totalItems={totalItems}
                    />
                </div>
            )}
            {showAdditiveGroupModal && (
                <AdditiveGroupFormModal
                    show={showAdditiveGroupModal}
                    handleClose={() => setShowAdditiveGroupModal(false)}
                    onSave={() => {
                        refetch();
                        setShowAdditiveGroupModal(false);
                    }}
                    language={language}
                    dishesData={[
                    { id: 1, name: 'Dish 1', category: 'Category 1', subcategory: 'Subcategory A' },
                    { id: 2, name: 'Dish 2', category: 'Category 1', subcategory: 'Subcategory A' },
                    { id: 3, name: 'Dish 3', category: 'Category 1', subcategory: 'Subcategory B' },
                    { id: 4, name: 'Dish 4', category: 'Category 2' },
                    { id: 5, name: 'Dish 5', category: 'Category 2' },
                        ]}
                />
            )}
            {showEditModal && editGroup && (
                <AdditiveGroupFormModal
                    show={showEditModal}
                    handleClose={() => {
                        setShowEditModal(false);
                        setEditGroup(null);
                    }}
                    onSave={() => {
                        refetch();
                        setShowEditModal(false);
                        setEditGroup(null);
                    }}
                    language={language}
                    dishesData={[
                        { id: 1, name: 'Dish 1', category: 'Category 1', subcategory: 'Subcategory A' },
                        { id: 2, name: 'Dish 2', category: 'Category 1', subcategory: 'Subcategory A' },
                        { id: 3, name: 'Dish 3', category: 'Category 1', subcategory: 'Subcategory B' },
                        { id: 4, name: 'Dish 4', category: 'Category 2' },
                        { id: 5, name: 'Dish 5', category: 'Category 2' },
                    ]}
                    existingGroup={editGroup}
                />
            )}
            {showDeleteModal && deleteGroup && (
                <ConfirmDeleteModal
                    show={showDeleteModal}
                    handleClose={() => {
                        setShowDeleteModal(false);
                        setDeleteGroup(null);
                    }}
                    onConfirm={confirmDeleteGroup}
                    groupName={deleteGroup.groupName}
                />
            )}
,

        </div>
    );
};

export default AdditivesTable;
