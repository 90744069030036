import React from 'react';
import {useTranslation} from "react-i18next";
import {Button, Col, Modal, Row} from 'react-bootstrap';
import { ReactSelect } from '@services/ui-components';
import {ErrorMessage, Field, Form, Formik} from "formik";

const ModalCloneMenuItem = (props: any) => {
    const {t} = useTranslation();
    return (
        <Modal centered show={props.showCloneModal} onHide={() => props.setShowCloneModal(false)}>
            <Modal.Header closeButton className="border-bottom-0">
                <Modal.Title className="fs-5">{t("Cloning")} {props.dishToClone?.name}</Modal.Title>
                {/* When main language will be added*/}
                {/*<div className="text-primary bg-violet-light rounded-1 fs-7 p-1 px-6 ms-3">Українська</div>*/}
            </Modal.Header>
            <Formik onSubmit={()=>{}} initialValues={{name: ''}}>
                {({isSubmitting, values}) => (
                    <Form>
                        <Modal.Body>
                            <div className="form-group px-6 mb-3">
                                <Row className="align-items-center">
                                    <Col sm={3} className="fs-7 fw-bold text-start">
                                        <label htmlFor="name">{t("Name dish")}
                                            <span className="text-danger ms-1">*</span>
                                        </label>
                                    </Col>
                                    <Col sm={9}>
                                        <Field type="text"
                                               name="name"
                                               className="fs-7 form-control rounded-1"
                                               id="cloneDish-name-field"
                                               value={props.clonedDishName}
                                               onChange={(e: any) => props.setClonedDishName(e.target.value)}
                                               maxLength={50}/>
                                    </Col>
                                </Row>
                                <Col sm={8} className="ms-auto">
                                    <ErrorMessage name="name" component="div" className="fs-7 text-danger" />
                                    <div className="fs-7 text-grey mt-1 px-6">
                                        0/50
                                    </div>
                                </Col>
                            </div>
                            <div className="form-group px-6">
                                <Row className="align-items-center">
                                    <Col sm={3}>
                                        <label htmlFor="registration-country-field" className="fs-7 fw-bold">{t('Category')}
                                            <span className="text-danger ms-1">*</span>
                                        </label>
                                    </Col>
                                    <Col sm={9}>
                                        <ReactSelect
                                            id="cloneDish-category-field"
                                            value={props.selectedCategory}
                                            options={props.categoriesData?.user.company.menuCategories.categories.map((category: any) => ({
                                                value: category.id,
                                                label: category.name,
                                            }))}
                                            onChange={(selectedOption) => props.setSelectedCategory(selectedOption)}
                                            isSearchable
                                            placeholder={""}
                                        />
                                    </Col>
                                </Row>
                                <Col sm={8} className="ms-auto">
                                    <ErrorMessage name="category">{msg => <p
                                        className="fs-7 p-1 text-danger m-0">{msg}</p>}</ErrorMessage>
                                </Col>
                            </div>
                        </Modal.Body>
                        <Modal.Footer className="justify-content-between border-top-0">
                            <Button variant="outline-primary" className="custom-btn" onClick={() => props.setShowCloneModal(false)}>
                                {t("cancel")}
                            </Button>
                            <Button variant="primary" className="custom-btn fw-bold" onClick={props.handleCloneDish}>
                                {t("Clone")}
                            </Button>
                        </Modal.Footer>
                    </Form>
                )}
            </Formik>
        </Modal>

    );
};

export default ModalCloneMenuItem;
