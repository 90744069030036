import React, { useEffect, useState } from 'react';
import { Formik, Field, Form, ErrorMessage, FieldArray } from 'formik';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { menuItemSchema } from './constants';
import {Button, Col, FormCheck, Row, CloseButton, Badge, OverlayTrigger, Tooltip} from 'react-bootstrap';
import {
    useCreateMenuItemMutation, useGetCurrentMenuItemQuery,
    useGetMenuAllergensQuery, useGetMenuItemsQuery,
    useGetMenuMarkersQuery,
    useGetMenuStatusesQuery,
    useGetMenuTypesQuery,
    useGetMenuUnitsQuery
} from '@me-team/host/src/modules/menu/graphql/menu.hooks';
import DialogMenuSupplement from '../Dialogs/MenuSupplement/DialogMenuSupplement';
import DialogCreateGroupSupplements from '../Dialogs/MenuSupplement/DialogCreateGroupSupplements';
import ImageCrop from '@services/ui-components/src/ImageCrop/ImageCrop';
import { currentCompanyIdVar } from '@me-team/host/src/apollo/globalVar/state';
import { useReactiveVar } from '@apollo/client';
import { ReactSelect, TableHeader, SpinnerLoader } from "@services/ui-components";

interface IMenuItem {
    companyId: number | null
    name: string
    types: any[]
    categories: any[]
    description: string
    composition: string
    unit: number | null
    portions: any[]
    cookingTime: string
    isLongCookingTime: boolean
    markers: any[]
    allergens: any[]
    supplements: any[]
    image: string
    isUpdateImage: boolean
}

interface SelectOption {
    label: string;
    value: string;
}

interface CookingTimeRange {
    value: string;
    label: string;
}

const CreateMenuItem = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [descriptionLength, setDescriptionLength] = useState(0);
    const [compositionLength, setCompositionLength] = useState(0);
    const [showSupplementMenu, setShowSupplementMenu] = useState(false);
    const [showCreateGroupSupplement, setShowCreateGroupSupplement] = useState(false);
    const currentCompanyId = useReactiveVar(currentCompanyIdVar);

    const { data: menuAllergensData } = useGetMenuAllergensQuery();
    const { data: menuMarkersData } = useGetMenuMarkersQuery();
    const { data: menuTypesData } = useGetMenuTypesQuery();
    const { data: menuUnitsData } = useGetMenuUnitsQuery();

    const cookingTimeOptions: CookingTimeRange[] = [
        {
            value: "5-10",
            label: `5-10 ${t("min")}`,
        },
        {
            value: "10-20",
            label: `10-20 ${t("min")}`,
        },
        {
            value: "20-30",
            label: `20-30 ${t("min")}`,
        },
        {
            value: "30-45",
            label: `30-45 ${t("min")}`,
        },
        {
            value: "45-60",
            label: `45-60 ${t("min")}`,
        },
        {
            value: "1hour",
            label: t("more one hour"),
        },

    ];

    const [createMenuItem] = useCreateMenuItemMutation();

    const menuUnitsOptions =
        menuUnitsData?.relations?.units.map((unit) => ({
            label: unit.name,
            value: unit.id.toString(),
        })) || [];

    const initialValues: IMenuItem = {
        companyId: parseInt(currentCompanyId),
        name: '',
        types: [],
        categories: [1], // put default category, need to change
        description: '',
        composition: '',
        unit: null,
        portions: [
            {
                name: 'Стандартна порція',
                volume: '',
                price: '',
            },
        ],
        cookingTime: '',
        isLongCookingTime: false,
        markers: [],
        allergens: [],
        supplements: [],
        image: '',
        isUpdateImage: false,
    };

    const handleSubmit = async (values: IMenuItem, { setSubmitting }) => {
        const input = {
            companyId: values.companyId,
            name: values.name,
            types: values.types,
            categories: values.categories,
            unit: Number(values.unit),
            portions: values.portions,
            description: values.description,
            composition: values.composition,
            cookingTime: values.cookingTime,
            isLongCookingTime: values.isLongCookingTime,
            markers: values.markers,
            allergens: values.allergens,
            supplements: values.supplements,
            image: values.image,
            isUpdateImage: values.isUpdateImage,
        };

        try {
            await createMenuItem({ variables: input });
            navigate('/branch/menu/items');
            setSubmitting(false);
        } catch (error) {
            console.error('Error creating menu item:', error);
            setSubmitting(false);
        }
    };

    return (
        <>
            <Formik
                initialValues={initialValues}
                validationSchema={menuItemSchema}
                onSubmit={handleSubmit}
            >
                {({setFieldValue, isSubmitting, values, errors}) => {
                    useEffect(() => {
                        if (menuTypesData) {
                            setFieldValue(
                                'types',
                                menuTypesData.menuTypes.map((type) => type.id)
                            );
                        }
                    }, [menuTypesData]);

                    const handlePhoto = (croppedImage: string) => {
                        setFieldValue('image', croppedImage);
                    };

                    return (
                        <>
                            <TableHeader
                                title={t("New dish")}
                                paths={[{ path: "/branches", label: t("Branches") }, { path: "/branch/menu/items", label: t("List of dishes") }, { path: "/branch/menu/create-item", label: t("New dish") }]}
                                buttons={
                                <Row className='gx-3 justify-content-end'>
                                    <Col sm={5} className="mb-2 mb-xxl-0">
                                        <div className="position-relative">
                                            <span className="position-absolute start-0 top-0 z-1 ps-2 pt-1 fs-8 text-grey">{t("Language version")}</span>
                                            <ReactSelect
                                                id="listOfDishes-language-field"
                                                value={{value: 'Українська (основна)', label: 'Українська (основна)'}}
                                                options={[]}
                                                placeholder={t("Type of branch")}
                                                onChange={() => {}}
                                                isSearchable={true}
                                                disabled={false}
                                                isMeMenu={true}
                                                isTitle={true}
                                            />
                                            <div className='position-absolute top-50 end-0 translate-middle-y ' style={{ height: '16px' }}>
                                                <OverlayTrigger
                                                    placement="bottom"
                                                    overlay={<Tooltip>{t("Translations can be added when editing")}</Tooltip>}>
                                                    <div className=" position-absolute top-50 translate-middle-y end-0 pt-1" style={{ marginRight: '36px' }}>
                                                        <i className="bi bi-info-circle"></i>
                                                    </div>
                                                </OverlayTrigger>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            }
                                colSize={6}
                                btnColSize={6}
                            />
                            <Form>
                                <Row className="mt-6">
                                    <Col lg={9} className="pe-lg-5">
                                        <h5 className="fs-24 text-capitalize mb-3 mb-lg-4">{t("Basic information")}</h5>
                                        <div className="pe-lg-4">
                                            <div className="mb-3">
                                                <Row className="align-items-center">
                                                    <Col xs={3} md={2}>
                                                        <label htmlFor="addDish-name-field" className="fs-7 fw-bold">{t('Dish name')}
                                                            <span className="text-danger ms-1">*</span>
                                                        </label>
                                                    </Col>
                                                    <Col xs={9} md={10}>
                                                        <Field id="addDish-name-field" name="name" placeholder={t('Add name')} maxLength={100}
                                                               className="form-control"/>
                                                    </Col>
                                                </Row>
                                                <Col xs={9} md={10} className="ms-auto">
                                                    <ErrorMessage name="name">{msg => <p className="fs-7 py-1 px-2 text-danger m-0">{msg}</p>}</ErrorMessage>
                                                </Col>
                                            </div>

                                            <Row className="mb-3 py-3">
                                                <Col xs={3} md={2}>
                                                    <label htmlFor="addDish-type-field" className="fs-7 fw-bold">{t('Type menu')}
                                                        <span className="text-danger ms-1">*</span>
                                                    </label>
                                                </Col>
                                                <Col xs={9} md={10}>
                                                    <div className="d-flex justify-content-start flex-wrap gap-3">
                                                        {menuTypesData?.menuTypes?.map((type) => (
                                                            <div key={type.id} className="mb-1 form-check form-switch ps-3">
                                                                <Field name="types">
                                                                    {({field}: any) => (
                                                                        <FormCheck
                                                                            type="checkbox"
                                                                            id={`switch-${type.id}`}
                                                                            className="fs-7 text-primary-grey"
                                                                            label={type.name}
                                                                            checked={field.value.includes(type.id)}
                                                                            onChange={() => {
                                                                                const nextValue = field.value.includes(type.id)
                                                                                    ? field.value.filter((id: number) => id !== type.id)
                                                                                    : [...field.value, type.id];
                                                                                setFieldValue('types', nextValue);
                                                                            }}
                                                                        />
                                                                    )}
                                                                </Field>
                                                            </div>
                                                        ))}
                                                    </div>
                                                    <div className="ms-auto">
                                                        <ErrorMessage name="name">{msg => <p className="fs-7 py-1 px-2 text-danger m-0">{msg}</p>}</ErrorMessage>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row className="mb-3">
                                                <Col xs={3} md={2}>
                                                    <label htmlFor="addDish-category-field" className="fs-7 fw-bold text-break">{t('categoriesSubcategories')}
                                                        <span className="text-danger ms-1">*</span>
                                                    </label>
                                                </Col>
                                                <Col xs={9} md={10}>
                                                    <div className="d-flex aligh-items-center flex-wrap gap-2 mb-2">
                                                        {/* add logic for select category*/}
                                                        {/*<Badge bg="additional-grey" className="rounded-1 p-2">*/}
                                                        {/*    <span className="text-dark fs-16 me-2">0123456789</span>*/}
                                                        {/*    <CloseButton className="fs-7 text-dark p-0" aria-label="Hide"/>*/}
                                                        {/*</Badge>*/}
                                                        <Button
                                                            id="addService-category-field"
                                                            variant="outline-primary"
                                                            className="fw-normal py-1 px-2"
                                                            style={{width: "149px"}}>{t("Select Category")}</Button>
                                                    </div>
                                                    <div className="ms-auto">
                                                        <ErrorMessage name="name">{msg => <p className="fs-7 py-1 px-2 text-danger m-0">{msg}</p>}</ErrorMessage>
                                                    </div>
                                                </Col>
                                            </Row>

                                            <Row className="mb-3">
                                                <Col xs={3} md={2}>
                                                    <label htmlFor="addDish-description-field" className="fs-7 fw-bold pt-2">{t("Description dish")}</label>
                                                </Col>
                                                <Col xs={9} md={10}>
                                                    <Field
                                                        as="textarea"
                                                        id="addDish-description-field"
                                                        name="description"
                                                        placeholder={t("Add description")}
                                                        className="form-control"
                                                        onChange={(e: any) => {
                                                            setDescriptionLength(e.target.value.length);
                                                            setFieldValue('description', e.target.value);
                                                        }}
                                                        maxLength={400}
                                                    />
                                                    <ErrorMessage name="description">{msg => <p
                                                        className="fs-7 p-1 text-danger m-0">{msg}</p>}</ErrorMessage>
                                                    <div className="fs-7 text-grey text-start mt-1 px-1">
                                                        {`${descriptionLength}/400`}
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row className="mb-3">
                                                <Col xs={3} md={2}>
                                                    <label htmlFor="addDish-composition-field" className="fs-7 fw-bold text-capitalize text-break pt-2">{t("Short composition")}</label>
                                                </Col>
                                                <Col xs={9} md={10}>
                                                    <Field
                                                        as="textarea"
                                                        id="addDish-composition-field"
                                                        name="composition"
                                                        placeholder={t("Add short composition")}
                                                        className="form-control"
                                                        onChange={(e: any) => {
                                                            setCompositionLength(e.target.value.length);
                                                            setFieldValue('composition', e.target.value);
                                                        }}
                                                        maxLength={400}
                                                    />
                                                    <ErrorMessage name="description">{msg => <p
                                                        className="fs-7 p-1 text-danger m-0">{msg}</p>}</ErrorMessage>
                                                    <div className="fs-7 text-grey text-start mt-1 px-1">
                                                        {`${compositionLength}/400`}
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs={3} md={2}>
                                                    <label htmlFor="addDish-measurement-field" className="fs-7 fw-bold text-break">{t('Units of measurement')}
                                                        <span className="text-danger ms-1">*</span>
                                                    </label>
                                                </Col>
                                                <Col xs={9} md={10}>
                                                    <ReactSelect
                                                        id="addDish-measurement-field"
                                                        classNamePrefix="select"
                                                        options={menuUnitsOptions}
                                                        value={menuUnitsOptions.find((option: any) => option.value === values.unit)}
                                                        onChange={(selectedOption: SelectOption | null) => {
                                                            setFieldValue('unit', selectedOption ? selectedOption.value : '');
                                                        }}
                                                        placeholder={t('Select Unit of Measurement')}
                                                        isClearable
                                                    />
                                                    <ErrorMessage name="unit" component="div" className="invalid-feedback"/>
                                                </Col>
                                            </Row>
                                        </div>

                                        <FieldArray
                                            name="portions"
                                            render={({remove, push}) => (
                                                <div className="my-4 my-lg-5 pe-lg-4">
                                                    <h5 className="fs-24 mb-3 mb-lg-4">{t('Servings')}</h5>
                                                    {values.portions.map((portion: string, index: number) => (
                                                        <Row className="mb-3">
                                                            <Col xs={3} md={2}>
                                                                <label htmlFor="addDish-servings-field" className="fs-7 fw-bold">{t('Serving name')}
                                                                    <span className="text-danger ms-1">*</span>
                                                                </label>
                                                            </Col>
                                                            <Col xs={9} md={10}>
                                                                <Row key={index}>
                                                                    <Col xs={12} xxl className="mb-3 mb-xxl-0">
                                                                        <Field name={`portions.${index}.name`}
                                                                               placeholder={t('Serving name')}
                                                                               className="form-control"/>
                                                                        <ErrorMessage
                                                                            name={`portions.${index}.name`}
                                                                            component="div"
                                                                            className="text-danger"/>
                                                                    </Col>
                                                                    <Col md={6} xxl={4} className="mb-3 mb-md-0">
                                                                        <Row className="gx-2">
                                                                            <Col xs={5}>
                                                                                <label htmlFor="addDish-weightVolume-field" className="fs-7 fw-bold">{t("Weight/ Volume dish")}
                                                                                    <span className="text-danger ms-1">*</span>
                                                                                </label>
                                                                            </Col>
                                                                            <Col xs={7}>
                                                                                <Field name={`portions.${index}.volume`}
                                                                                       className="form-control"
                                                                                       placholder={t("Weight/Volume")}
                                                                                       id="addDish-weightVolume-field"
                                                                                       type="number"/>
                                                                                <ErrorMessage
                                                                                    name={`portions.${index}.volume`}
                                                                                    component="div"
                                                                                    className="text-danger"/>
                                                                            </Col>
                                                                        </Row>
                                                                    </Col>
                                                                    <Col md={6} xxl={4}>
                                                                        <Row className="gx-2 align-items-center">
                                                                            <Col xs={5} lg={4}>
                                                                                <label htmlFor="addDish-price-field" className="fs-7 fw-bold">{t("Price")}
                                                                                    <span className="text-danger ms-1">*</span>
                                                                                </label>
                                                                            </Col>
                                                                            <Col xs={7} lg={8} className="d-flex">
                                                                                <Field name={`portions.${index}.price`}
                                                                                       id="addDish-price-field"
                                                                                       placeholder={t('Price')}
                                                                                       className="form-control"
                                                                                       type="number"/>
                                                                                {values.portions.length > 1 && index >= 1 && (
                                                                                    <div className="ms-2">
                                                                                        <Button
                                                                                            type="button"
                                                                                            variant="outline-secondary"
                                                                                            className="w-100 px-3 py-6 fs-normal rounded-1 align-self-start"
                                                                                            onClick={() => remove(index)}
                                                                                        >
                                                                                            <i className="bi bi-trash3 text-dark"></i>
                                                                                        </Button>
                                                                                    </div>
                                                                                )}
                                                                                <ErrorMessage
                                                                                    name={`portions.${index}.price`}
                                                                                    component="div"
                                                                                    className="text-danger"/>
                                                                            </Col>
                                                                        </Row>
                                                                    </Col>

                                                                    {index === values.portions.length - 1 && (
                                                                        <Col xs={12} className="mt-3">
                                                                            <Button type="button"
                                                                                    variant="light"
                                                                                    className="fs-7 text-primary p-0 border-0 bg-transparent"
                                                                                    onClick={() => push({
                                                                                        name: '',
                                                                                        volume: '',
                                                                                        price: ''
                                                                                    })}>
                                                                                <i className="bi bi-plus me-1"></i>
                                                                                {t("Add another portion option")}
                                                                            </Button>
                                                                        </Col>
                                                                    )}
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                    ))}
                                                </div>
                                            )}
                                        />

                                        <h5 className="fs-24 mb-3 mb-lg-4">{t('Additional Information')}</h5>
                                        <Row className="mb-3">
                                            <Col xs={3} md={2}>
                                                <label htmlFor="addDish-cookingTime-field" className="fs-7 fw-bold text-break">{t('Cooking time')}</label>
                                            </Col>
                                            <Col xs={9} md={10}>
                                                <ReactSelect
                                                    id="addDish-cookingTime-field"
                                                    classNamePrefix="select"
                                                    options={cookingTimeOptions}
                                                    value={cookingTimeOptions.find((option: any) => option.value === values.cookingTime)}
                                                    onChange={(selectedOption: SelectOption | null) => {
                                                        setFieldValue('cookingTime', selectedOption ? selectedOption.value : '');
                                                    }}
                                                    placeholder={t('Select cooking time')}
                                                    isClearable
                                                />
                                                <ErrorMessage name="unit" component="div" className="invalid-feedback"/>
                                                <div className="form-check mt-2 mb-0">
                                                    <Field
                                                        type="checkbox"
                                                        name="isLongCookingTime"
                                                        id="isLongCookingTime"
                                                        className="form-check-input"
                                                        checked={values.isLongCookingTime}
                                                    />
                                                    <label className="form-check-label fs-7" htmlFor="isLongCookingTime">
                                                        {t('Display icon "long cooking"')}
                                                    </label>
                                                    <OverlayTrigger
                                                        overlay={<Tooltip>{t('There will be a marker next to this dish in the menu')}</Tooltip>}>
                                                        <i className="bi bi-info-circle ms-1 fs-7 pt-1"></i>
                                                    </OverlayTrigger>
                                                    <ErrorMessage name="isLongCookingTime" component="div"
                                                                  className="text-danger"/>
                                                </div>
                                            </Col>
                                        </Row>

                                        <Row className="mb-3">
                                            <Col xs={3} md={2}>
                                                <label htmlFor="addDish-warning-field" className="fs-7 fw-bold">{t('Warning')}</label>
                                            </Col>
                                            <Col xs={9} md={10}>
                                                <div className="d-flex justify-content-start align-content-center flex-wrap border rounded-1 py-6 gap-3">
                                                    {menuMarkersData?.menuMarkers?.map((marker) => (
                                                        <div key={marker.id} className="form-check form-switch">
                                                            <Field name="markers">
                                                                {({field}: any) => (
                                                                    <FormCheck
                                                                        type="checkbox"
                                                                        id={`switch-${marker.id}`} label={marker.name}
                                                                        className="fs-7 text-primary-grey ps-2 mb-0"
                                                                        checked={field.value.includes(marker.id)}
                                                                        onChange={() => {
                                                                            const nextValue = field.value.includes(marker.id)
                                                                                ? field.value.filter((id: number) => id !== marker.id)
                                                                                : [...field.value, marker.id];
                                                                            setFieldValue('markers', nextValue);
                                                                        }}
                                                                    />
                                                                )}
                                                            </Field>
                                                        </div>
                                                    ))}
                                                </div>
                                                <small className="text-grey">{t('Optional small icons placed next to the item title')}</small>
                                            </Col>
                                        </Row>

                                        <Row className="mb-3">
                                            <Col xs={3} md={2}>
                                                <label htmlFor="addDish-allergen-field" className="fs-7 fw-bold">{t('Allergen list')}</label>
                                            </Col>
                                            <Col xs={9} md={10}>
                                                <div className="d-flex justify-content-start flex-wrap border rounded-1 py-6 gap-3">
                                                    {menuAllergensData?.menuAllergens?.map((allergen, index) => (
                                                        <div key={allergen.id} className="form-check form-switch">
                                                            <Field name="allergens">
                                                                {({field}: any) => (
                                                                    <FormCheck
                                                                        type="checkbox"
                                                                        id={`switch-${allergen.id}`}
                                                                        label={<><span className="fs-7 text-primary-grey me-1">{index + 1}</span>
                                                                                ({allergen.name})</>}
                                                                        className="fs-7 text-grey ps-2 mb-0"
                                                                        checked={field.value.includes(allergen.id)}
                                                                        onChange={() => {
                                                                            const nextValue = field.value.includes(allergen.id)
                                                                                ? field.value.filter((id: number) => id !== allergen.id)
                                                                                : [...field.value, allergen.id];
                                                                            setFieldValue('allergens', nextValue);
                                                                        }}
                                                                    />
                                                                )}
                                                            </Field>
                                                        </div>
                                                    ))}
                                                </div>
                                                <small className="text-grey">{t('Small numbers placed next to the item title. Food ingredients that must be declared as allergens in the EU')}</small>
                                            </Col>
                                        </Row>

                                        <Row className="mb-4 mb-lg-5">
                                            <Col xs={3} md={2}>
                                                <label htmlFor="addDish-options-field" className="fs-7 fw-bold">{t('List of options/additives')}</label>
                                            </Col>
                                            <Col xs={9} md={10}>
                                                <div className="d-flex align-items-center w-100 mb-3">
                                                    <div className="w-100">
                                                        <ReactSelect
                                                            components={{DropdownIndicator: () => null}}
                                                            options={[]}
                                                            value={[{
                                                                label: "123",
                                                                value: "1",
                                                            }]}
                                                            placeholder={t('Select options/additives')}
                                                            isClearable={false}
                                                            closeMenuOnSelect={false}
                                                            openMenuOnClick={false}
                                                            isMulti
                                                        />
                                                    </div>
                                                    <Button onClick={() => setShowSupplementMenu(true)} type="button"
                                                            variant="outline-secondary"
                                                            className="h-100 ms-3 px-3 py-6 fs-normal rounded-1 align-self-start">
                                                        <i className="bi bi-plus-lg text-dark"></i>
                                                    </Button>
                                                    <ErrorMessage name="unit" component="div" className="invalid-feedback">test</ErrorMessage>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col lg={3} className="ms-auto ps-lg-0">
                                        <h5 className="fs-24 mb-4">{t('Photo dish')}</h5>
                                        <ImageCrop
                                            key={values.image}
                                            previewPhoto={values.image}
                                            setFieldValue={setFieldValue}
                                            fieldSetter={handlePhoto}
                                            fieldName="image"
                                            modalTitle={t('Dish Photo')}
                                            dismissImage={() => setFieldValue('image', null)}
                                            maxFileSize={3 * 1024 * 1024}
                                            minWidth={500}
                                            minHeight={500}
                                            acceptedFormats={['image/jpeg', 'image/png']}
                                            notBGinfo
                                            isMenuInfo
                                        />
                                    </Col>
                                </Row>
                                <div className="d-sm-flex my-4 mt-lg-0">
                                    <Button id={"addDish-cancel-button"} onClick={() => navigate('/branch/menu/items')} variant="outline-primary" className="fw-normal mb-3 mb-sm-0 me-3 custom-btn rounded-1">
                                        {t("Cancel")}
                                    </Button>
                                    <Button id={"addDish-submit-button"} type="submit" variant="primary" disabled={isSubmitting} className="fw-bold custom-btn rounded-1">
                                        {isSubmitting ? <SpinnerLoader variant="light" loadingText={t("Saving...")}/> : t('Save')}
                                    </Button>
                                </div>
                            </Form>
                        </>
                    )
                }}
            </Formik>
            <DialogMenuSupplement
                show={showSupplementMenu}
                setShow={setShowSupplementMenu}
                onCreateNew={() => {
                    setShowSupplementMenu(false);
                    setShowCreateGroupSupplement(true);
                }}
            />
            <DialogCreateGroupSupplements
                show={showCreateGroupSupplement}
                onHide={() => setShowCreateGroupSupplement(false)}
            />
        </>
    );
};

export default CreateMenuItem;
