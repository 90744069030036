import React, { useState } from 'react';
import { Table, Button, OverlayTrigger, Tooltip, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Pagination, TableHeader } from '@services/ui-components';
import {
    GetMenuCategoriesQueryVariables,
    useGetMenuCategoriesQuery,
} from '@me-team/host/src/modules/menu/graphql/menu.hooks';
import MoveCategoryModal from './MoveCategoryModal';
import CategoryFormModal from './CategoryFormModal';
import DeleteCategoryModal from './DeleteCategoryModal';

interface ExpandedCategoriesState {
    [key: number]: boolean;
}

const CategoriesTable: React.FC = () => {
    const { t } = useTranslation();
    const [expandedCategories, setExpandedCategories] = useState<ExpandedCategoriesState>({});
    const [showFilters, setShowFilters] = useState(false);
    const [currentFilter, setCurrentFilter] = useState({ name: '', branchTypeId: '' });
    const [filter, setFilter] = useState({ name: '', branchTypeId: '' });
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(20);
    const toggleFilters = () => setShowFilters(!showFilters);
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const [categoryToDelete, setCategoryToDelete] = useState<any>(null);

    const toggleExpandCategory = (categoryId: number) => {
        setExpandedCategories((prev) => ({
            ...prev,
            [categoryId]: !prev[categoryId],
        }));
    };

    const { data: categoriesData, loading, error, refetch } = useGetMenuCategoriesQuery({
        variables: {
            id: null,
            search: filter.name || null,
            page: currentPage,
            itemsPerPage,
            isDeleted: null,
        } as GetMenuCategoriesQueryVariables,
    });

    const [showCategoryModal, setShowCategoryModal] = useState<boolean>(false);
    const [selectedCategory, setSelectedCategory] = useState<any>(null); // For editing/restoring
    const [parentCategory, setParentCategory] = useState<any>(null); // For creating subcategories

    const [showMoveCategoryModal, setShowMoveCategoryModal] = useState<boolean>(false);
    const [categoryToMove, setCategoryToMove] = useState<any>(null);

    const handleArchiveCategory = async (category: any) => {
        // Instead of directly archiving, open the delete modal
        setCategoryToDelete(category);
        setShowDeleteModal(true);
    };

    const handleDeleteCategory = () => {
        console.log('Deleting category:', categoryToDelete);
        setShowDeleteModal(false);
        refetch();
    };

    const handleEditCategory = (category: any) => {
        setSelectedCategory(category);
        setParentCategory(category.parent || null);
        setShowCategoryModal(true);
    };

    const handleAddSubcategory = (category: any) => {
        setSelectedCategory(null);
        setParentCategory(category);
        setShowCategoryModal(true);
    };

    const handleMoveCategory = (category: any) => {
        setCategoryToMove(category);
        setShowMoveCategoryModal(true);
    };

    const handleRestoreCategory = (category: any) => {
        setSelectedCategory(category);
        setParentCategory(category.parent || null);
        setShowCategoryModal(true);
    };

    const handlePageChange = (pageNumber: number) => {
        setCurrentPage(pageNumber);
    };

    const handleApplyFilter = () => {
        setFilter(currentFilter);
        setCurrentPage(1);
    };

    const handleClearFilter = () => {
        setFilter({ name: '', branchTypeId: '' });
        setCurrentFilter({ name: '', branchTypeId: '' });
        setCurrentPage(1);
    };

    if (loading) return <div>{t('Loading...')}</div>;
    if (error) return <div>{t('An error occurred:')} {error.message}</div>;

    const categories = categoriesData?.user?.company?.menuCategories?.categories || [];
    const pageCount = parseInt(categoriesData?.user?.company?.menuCategories?.pageCount || '0');
    const totalItems = categoriesData?.user?.company?.menuCategories?.totalCount || 0;
    const firstItemIndex = (currentPage - 1) * itemsPerPage + 1;
    const lastItemIndex = Math.min(currentPage * itemsPerPage, totalItems);

    return (
        <div className="mb-4">
            <TableHeader
                title={t("Categories")}
                paths={[{ path: "/category", label: t('Categories') }]}
                buttons={
                    <Row className="gx-3 justify-content-end">
                        <Col sm={6} lg={5} xxl={4}>
                            <Button
                                variant="primary"
                                className="fw-normal w-100 rounded-1"
                                onClick={() => {
                                    setSelectedCategory(null);
                                    setParentCategory(null);
                                    setShowCategoryModal(true);
                                }}
                            >
                                <i className="bi bi-plus-lg me-2"></i>
                                {t("Add New Category")}
                            </Button>
                        </Col>
                        <Col lg={6}>
                            <button className="btn btn-additional-violet fw-normal border-primary text-primary w-100" onClick={toggleFilters}><i className="bi bi-funnel me-2"></i>{t("filter")}</button>
                        </Col>
                    </Row>
                }
                showFilters={showFilters}
                colSize={7}
                btnColSize={5}
            >
                <Row className="gx-4 pb-6">
                    <Col lg={8} xl={9}>
                        <Row className="gx-3">
                            <Col md={12}>
                                <div className="position-relative mb-3 mb-lg-0">
                                    <input
                                        type="text"
                                        className="form-control rounded-1"
                                        placeholder={t("Search by name")}
                                        value={currentFilter.name}
                                        onChange={(e) => setCurrentFilter({ ...currentFilter, name: e.target.value })}
                                    />
                                    <i className="bi bi-search position-absolute top-50 end-0 translate-middle-y text-dark me-3"></i>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col lg={4} xl={3}>
                        <Row className="gx-3 h-100">
                            <Col sm={6} className="mb-2 mb-sm-0">
                                <Button
                                    variant="outline-dark"
                                    className="border-grey w-100 h-100 fw-normal rounded-1"
                                    onClick={handleClearFilter}
                                >
                                    {t("Clear")}
                                </Button>
                            </Col>
                            <Col sm={6}>
                                <Button
                                    variant="primary"
                                    className="w-100 h-100 rounded-1 fw-normal px-2"
                                    onClick={handleApplyFilter}
                                >
                                    {t("Apply")}
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </TableHeader>
            <Table striped bordered hover responsive>
                <thead>
                <tr>
                    <th>{t('Категорії / Підкатегорії')}</th>
                    <th>{t('Опис')}</th>
                    <th>{t('Іконка')}</th>
                    <th>{t('Кількість Страв')}</th>
                    <th>{t('Дії')}</th>
                </tr>
                </thead>
                <tbody>
                {categories.map((category) => (
                    <React.Fragment key={category.id}>
                        {/* Main Category Row */}
                        <tr className={category.deleted ? 'text-muted' : ''}>
                            <td>
                                <Button variant="link" className="p-0" onClick={() => toggleExpandCategory(category.id)}>
                                    {expandedCategories[category.id] ? (
                                        <i className="bi bi-chevron-up"></i>
                                    ) : (
                                        <i className="bi bi-chevron-down"></i>
                                    )}
                                </Button>
                                {' '}{category.name}
                                {category.deleted && (
                                    <span className="ms-2 badge bg-secondary">{t('Archived')}</span>
                                )}
                            </td>
                            <td>{category.description || t('No description')}</td>
                            <td>
                                {category.favicon ? (
                                    <i className={`bi ${category.favicon}`}></i>
                                ) : (
                                    <i className="bi bi-question-circle"></i>
                                )}
                            </td>
                            <td>{category.numberOfItems}</td>
                            <td>
                                <div className="d-flex gap-2">
                                    {!category.deleted && (
                                        <>
                                            {/* Edit Button */}
                                            <OverlayTrigger overlay={<Tooltip>{t('Edit')}</Tooltip>}>
                                                <Button
                                                    variant="outline-primary"
                                                    onClick={() => handleEditCategory(category)}
                                                >
                                                    <i className="bi bi-pencil"></i>
                                                </Button>
                                            </OverlayTrigger>
                                            {/* Create Subcategory Button */}
                                            <OverlayTrigger overlay={<Tooltip>{t('Create Subcategory')}</Tooltip>}>
                                                <Button
                                                    variant="outline-primary"
                                                    onClick={() => handleAddSubcategory(category)}
                                                >
                                                    <i className="bi bi-plus-lg"></i>
                                                </Button>
                                            </OverlayTrigger>
                                            {/* Move Category Button */}
                                            {category.subcategories.length === 0 && (
                                                <OverlayTrigger overlay={<Tooltip>{t('Move Category')}</Tooltip>}>
                                                    <Button
                                                        variant="outline-primary"
                                                        onClick={() => handleMoveCategory(category)}
                                                    >
                                                        <i className="bi bi-arrow-right"></i>
                                                    </Button>
                                                </OverlayTrigger>
                                            )}
                                            {/* Archive Button */}
                                            <OverlayTrigger overlay={<Tooltip>{t('Archive')}</Tooltip>}>
                                                <Button
                                                    variant="outline-danger"
                                                    onClick={() => handleArchiveCategory(category)}
                                                >
                                                    <i className="bi bi-trash"></i>
                                                </Button>
                                            </OverlayTrigger>
                                        </>
                                    )}
                                    {category.deleted && (
                                        <>
                                            {/* Restore Button */}
                                            <OverlayTrigger overlay={<Tooltip>{t('Restore')}</Tooltip>}>
                                                <Button
                                                    variant="outline-success"
                                                    onClick={() => handleRestoreCategory(category)}
                                                >
                                                    <i className="bi bi-arrow-clockwise"></i>
                                                </Button>
                                            </OverlayTrigger>
                                        </>
                                    )}
                                </div>
                            </td>
                        </tr>
                        {/* Subcategories */}
                        {expandedCategories[category.id] && category.subcategories.length > 0 && (
                            category.subcategories.map((sub) => (
                                <tr key={sub.id} className={`bg-light ${sub.deleted ? 'text-muted' : ''}`}>
                                    <td className="ps-5">
                                        {sub.name}
                                        {sub.deleted && (
                                            <span className="ms-2 badge bg-secondary">{t('Archived')}</span>
                                        )}
                                    </td>
                                    <td>{sub.description || t('No description')}</td>
                                    <td>
                                        {sub.favicon ? (
                                            <i className={`bi ${sub.favicon}`}></i>
                                        ) : (
                                            <i className="bi bi-question-circle"></i>
                                        )}
                                    </td>
                                    <td>{sub.numberOfItems || 0}</td>
                                    <td>
                                        <div className="d-flex gap-2">
                                            {!sub.deleted && (
                                                <>
                                                    {/* Edit Button */}
                                                    <OverlayTrigger overlay={<Tooltip>{t('Edit')}</Tooltip>}>
                                                        <Button
                                                            variant="outline-secondary"
                                                            onClick={() => handleEditCategory(sub)}
                                                        >
                                                            <i className="bi bi-pencil"></i>
                                                        </Button>
                                                    </OverlayTrigger>
                                                    {/* Move Subcategory Button */}
                                                    <OverlayTrigger overlay={<Tooltip>{t('Move Category')}</Tooltip>}>
                                                        <Button
                                                            variant="outline-secondary"
                                                            onClick={() => handleMoveCategory(sub)}
                                                        >
                                                            <i className="bi bi-arrow-right"></i>
                                                        </Button>
                                                    </OverlayTrigger>
                                                    {/* Archive Button */}
                                                    <OverlayTrigger overlay={<Tooltip>{t('Archive')}</Tooltip>}>
                                                        <Button
                                                            variant="outline-danger"
                                                            onClick={() => handleArchiveCategory(sub)}
                                                        >
                                                            <i className="bi bi-trash"></i>
                                                        </Button>
                                                    </OverlayTrigger>
                                                </>
                                            )}
                                            {sub.deleted && (
                                                <>
                                                    {/* Restore Button */}
                                                    <OverlayTrigger overlay={<Tooltip>{t('Restore')}</Tooltip>}>
                                                        <Button
                                                            variant="outline-success"
                                                            onClick={() => handleRestoreCategory(sub)}
                                                        >
                                                            <i className="bi bi-arrow-clockwise"></i>
                                                        </Button>
                                                    </OverlayTrigger>
                                                </>
                                            )}
                                        </div>
                                    </td>
                                </tr>
                            ))
                        )}
                    </React.Fragment>
                ))}
                </tbody>
            </Table>
            {categories?.length ? (
                <div className="d-flex justify-content-between align-items-center">
                    <Pagination
                        t={t}
                        pages={Number(pageCount)}
                        currentPage={currentPage}
                        onPageChange={handlePageChange}
                        firstItemIndex={firstItemIndex}
                        lastItemIndex={lastItemIndex}
                        totalItems={totalItems}
                    />
                </div>
            ) : null}

             {/*Category Form Modal */}
            {showCategoryModal && (
                <CategoryFormModal
                    show={showCategoryModal}
                    handleClose={() => setShowCategoryModal(false)}
                    category={selectedCategory}
                    parentCategory={parentCategory}
                    onSave={() => {
                        refetch();
                        setShowCategoryModal(false);
                    }}
                />
            )}

            {showMoveCategoryModal && categoryToMove && (
                <MoveCategoryModal
                    show={showMoveCategoryModal}
                    handleClose={() => setShowMoveCategoryModal(false)}
                    categoryToMove={categoryToMove}
                    onMove={() => {
                        refetch();
                        setShowMoveCategoryModal(false);
                    }}
                 categories={[]}/>
            )}

            {showDeleteModal && categoryToDelete && (
                <DeleteCategoryModal
                    show={showDeleteModal}
                    handleClose={() => setShowDeleteModal(false)}
                    category={categoryToDelete}
                    onDelete={handleDeleteCategory}
                />
            )}
        </div>
    );
};

export default CategoriesTable;
