import * as Yup from 'yup';

export const menuItemSchema = Yup.object().shape({
    name: Yup.string(),
    type: Yup.string(),
    category: Yup.string(),
    description: Yup.string(),
    price: Yup.number(),
    volume: Yup.string()
});
